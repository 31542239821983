

































































































.webpage {
  position: relative;
  overflow-y: hidden;
}

.gm-logo {
  &--full-height {
    position: absolute;
    height: 80vh;
    bottom: -2%;
    left: -2%;
  }
}

.gm-information {
  z-index: 2;
  padding-left: 40vh;
}
