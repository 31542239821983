/// Vuetify screen sizes

$breakpoints: (
  'xs': 0,
  'sm': 600px,
  'md': 960px,
  'lg': 1280px - 16px,
  'xl': 1920px - 16px
);


@mixin screen_xs {
  @media (min-width: map-get($breakpoints, "xs")) {
    @content;
  }
}
@mixin screen_sm {
  @media (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}
@mixin screen_md {
  @media (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}
@mixin screen_lg {
  @media (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}
@mixin screen_xl {
  @media (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}