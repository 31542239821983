



































.webpage {
  position: relative;
  overflow-y: hidden;
}

.gm-logo {
  &--full-height {
    position: absolute;
    height: 80vh;
    bottom: -2%;
    left: -2%;
    z-index: 2;
  }

  &--mask {
    position: absolute;
    width: 20vw;
    height: 100vh;
    left: 0;
    z-index: 1;
    bottom: 0;
  }
}

.gm-background {
  &--full-height {
    position: absolute;
    height: 75vh;
    bottom: -2%;
    right: 0;
  }
}

.gm-information {
  z-index: 2;
  padding-left: 40vh;
}
