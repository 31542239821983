






























































.img-slider {
  &--bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &--mask {
    position: absolute;
    z-index: 2;
    width: 10%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    left: 0;
  }

  &--icon {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 7.5%;
      top: 61.9%;
      left: 49.1%;
    }
    &.img--2 {
      width: 6.5%;
      top: 63%;
      left: 59.4%;
    }
    &.img--3 {
      width: 6.5%;
      top: 62.8%;
      left: 69.3%;
    }
    &.img--4 {
      width: 5.5%;
      top: 64.5%;
      left: 79.3%;
    }
    &.img--5 {
      width: 7.5%;
      top: 63%;
      left: 87.8%;
    }
  }

  &--btn {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 7.5%;
      top: 5.5%;
      left: 1.8%;
    }
    &.img--2 {
      width: 7.5%;
      top: 5.5%;
      left: 17.5%;
    }
    &.img--3 {
      width: 7.5%;
      top: 4.5%;
      left: 30%;
    }
    &.img--4 {
      width: 6%;
      top: 6.9%;
      left: 51%;
    }
    &.img--5 {
      width: 7.5%;
      top: 5.5%;
      left: 94.4%;
    }
  }
}

.gm-divider {
  position: absolute;
  background: #fff;
  z-index: 3;
  width: 55%;
  bottom: 12%;
  right: 0;
}

.icon-title {
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  color: #212121;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  width: 20%;
  top: 78%;

  &.icon--1 {
    left: 42.5%;
  }
  &.icon--2 {
    left: 52.5%;
  }
  &.icon--3 {
    left: 62.5%;
  }
  &.icon--4 {
    left: 72.1%;
  }
  &.icon--5 {
    left: 81.6%;
  }
}

.text-slider {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 7;

  &.gm-title {
    top: -9%;
    right: 1%;
    color: #212121;
  }
  &.text--1 {
    width: 50%;
    height: 15%;
    top: 85%;
    left: 6%;
  }
  &.text--2 {
    font-size: 0.8rem;
    width: 50%;
    height: 10%;
    top: 88%;
    left: 49%;

    strong {
      letter-spacing: 5px;
    }
  }
}
