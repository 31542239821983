

































































































.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 25%;
      top: 50%;
      left: -12%;
      z-index: 4;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 48%;
      top: 22%;
      left: 8%;
      z-index: 2;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 20%;
      z-index: 3;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 62%;
      top: 18%;
      left: 48%;
      z-index: 1;
    }
  }
}

.btn-line {
  &--1 {
    opacity: 0;
    z-index: 5;
    bottom: 30%;
    left: -12%;
  }

  &--2 {
    opacity: 0;
    bottom: 48%;
    left: 18%;
  }

  &--3 {
    opacity: 0;
    z-index: 5;
    bottom: 38%;
    left: 41%;
  }

  &--4 {
    opacity: 0;
    bottom: 43%;
    left: 66%;
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 30%;
  height: 34%;

  &.control {
    &--1 {
      top: 55%;
      left: -12%;
      z-index: 6;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 30%;
      left: 18%;
      z-index: 6;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 66%;
      top: 64%;
      left: 18%;
      z-index: 6;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 30%;
      left: 70%;
      z-index: 6;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
