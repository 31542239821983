




























































.img-slider {
  &--bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &--mask {
    position: absolute;
    z-index: 2;
    width: 10%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    left: 0;
  }

  &--icon {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 6.5%;
      top: 65.4%;
      left: 54.5%;
    }
    &.img--2 {
      width: 7.5%;
      top: 65%;
      left: 63.7%;
    }
    &.img--3 {
      width: 5.5%;
      top: 66.3%;
      left: 74.8%;
    }
    &.img--4 {
      width: 6%;
      top: 65.8%;
      left: 84.1%;
    }
    &.img--5 {
      width: 7.5%;
      top: 64.2%;
      left: 92.8%;
    }
  }

  &--btn {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 7.5%;
      top: 16.5%;
      left: 1.8%;
    }
    &.img--2 {
      width: 7.5%;
      top: 16.7%;
      left: 32.4%;
    }
    &.img--3 {
      width: 7%;
      top: 7.5%;
      left: 70.8%;
    }
    &.img--4 {
      width: 7%;
      top: 17.6%;
      left: 94.5%;
    }
    &.img--5 {
      width: 7.5%;
      top: 63.8%;
      left: 5.3%;
    }
  }
}

.gm-divider {
  position: absolute;
  background: #fff;
  z-index: 3;
  width: 55%;
  bottom: 13%;
  right: 0;
}

.icon-title {
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  width: 20%;
  top: 83%;

  &.icon--1 {
    left: 47.5%;
  }
  &.icon--2 {
    left: 57.5%;
  }
  &.icon--3 {
    left: 67.7%;
  }
  &.icon--4 {
    left: 76.8%;
  }
  &.icon--5 {
    left: 86%;
  }
}

.text-slider {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 7;

  &.gm-title {
    top: -7%;
    right: 1%;
    color: #212121;
  }
  &.text--1 {
    width: 50%;
    height: 15%;
    top: 84%;
    left: 6%;
  }
  &.text--2 {
    font-size: 0.8rem;
    width: 50%;
    height: 10%;
    top: 87%;
    left: 49%;

    strong {
      letter-spacing: 5px;
    }
  }
}
