






































































































.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 23%;
      top: 61%;
      left: 2%;
      z-index: 2;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 38%;
      left: 39%;
      z-index: 3;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 9%;
      top: 44%;
      left: 50%;
      z-index: 5;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 15%;
      top: 39%;
      left: 55%;
      z-index: 4;
    }
    &--5 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 12%;
      left: 0%;
      z-index: 1;
    }
  }
}

.btn-line {
  &--1 {
    opacity: 0;
    z-index: 6;
    bottom: 31%;
    left: -1%;
  }

  &--2 {
    opacity: 0;
    bottom: 25%;
    left: 13%;
  }

  &--3 {
    opacity: 0;
    bottom: 17%;
    left: 29%;
  }

  &--4 {
    opacity: 0;
    bottom: 45%;
    left: 49%;
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 20%;
  height: 20%;

  &.control {
    &--1 {
      top: 62%;
      left: 4%;
      z-index: 7;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      height: 22%;
      top: 40%;
      left: 30%;
      z-index: 7;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 8%;
      height: 33%;
      top: 40%;
      left: 50%;
      z-index: 7;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 40%;
      left: 58%;
      z-index: 7;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
