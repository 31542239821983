@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?dmzufc');
  src:  url('fonts/icomoon.eot?dmzufc#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?dmzufc') format('truetype'),
    url('fonts/icomoon.woff?dmzufc') format('woff'),
    url('fonts/icomoon.svg?dmzufc#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-airplane .path1:before {
  content: "\e900";
  color: rgb(230, 231, 232);
}
.icon-airplane .path2:before {
  content: "\e901";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path3:before {
  content: "\e902";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path4:before {
  content: "\e903";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path5:before {
  content: "\e904";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path6:before {
  content: "\e905";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-airplane .path7:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-airplane .path8:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path9:before {
  content: "\e908";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path10:before {
  content: "\e909";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-airplane .path11:before {
  content: "\e90a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-airplane .path12:before {
  content: "\e90b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-airplane .path13:before {
  content: "\e90c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-airplane .path14:before {
  content: "\e90d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-airplane .path15:before {
  content: "\e90e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-airplane .path16:before {
  content: "\e90f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-airplane .path17:before {
  content: "\e910";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-artificial-intelligence .path1:before {
  content: "\e911";
  color: rgb(230, 231, 232);
}
.icon-artificial-intelligence .path2:before {
  content: "\e912";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-artificial-intelligence .path3:before {
  content: "\e913";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-artificial-intelligence .path4:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-artificial-intelligence .path5:before {
  content: "\e915";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-artificial-intelligence .path6:before {
  content: "\e916";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-artificial-intelligence .path7:before {
  content: "\e917";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-artificial-intelligence .path8:before {
  content: "\e918";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-artificial-intelligence .path9:before {
  content: "\e919";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-artificial-intelligence .path10:before {
  content: "\e91a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-artificial-intelligence .path11:before {
  content: "\e91b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-artificial-intelligence .path12:before {
  content: "\e91c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path13:before {
  content: "\e91d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path14:before {
  content: "\e91e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path15:before {
  content: "\e91f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path16:before {
  content: "\e920";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path17:before {
  content: "\e921";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path18:before {
  content: "\e922";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path19:before {
  content: "\e923";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-artificial-intelligence .path20:before {
  content: "\e924";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path21:before {
  content: "\e925";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path22:before {
  content: "\e926";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path23:before {
  content: "\e927";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path24:before {
  content: "\e928";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-artificial-intelligence .path25:before {
  content: "\e929";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-artificial-intelligence .path26:before {
  content: "\e92a";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-artificial-intelligence .path27:before {
  content: "\e92b";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-automatic-lamp .path1:before {
  content: "\e92c";
  color: rgb(230, 231, 232);
}
.icon-automatic-lamp .path2:before {
  content: "\e92d";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-automatic-lamp .path3:before {
  content: "\e92e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-automatic-lamp .path4:before {
  content: "\e92f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-automatic-lamp .path5:before {
  content: "\e930";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-automatic-lamp .path6:before {
  content: "\e931";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-automatic-lamp .path7:before {
  content: "\e932";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-automatic-lamp .path8:before {
  content: "\e933";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-automatic-lamp .path9:before {
  content: "\e934";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automatic-lamp .path10:before {
  content: "\e935";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automatic-lamp .path11:before {
  content: "\e936";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automation .path1:before {
  content: "\e937";
  color: rgb(230, 231, 232);
}
.icon-automation .path2:before {
  content: "\e938";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-automation .path3:before {
  content: "\e939";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-automation .path4:before {
  content: "\e93a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-automation .path5:before {
  content: "\e93b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-automation .path6:before {
  content: "\e93c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-automation .path7:before {
  content: "\e93d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-automation .path8:before {
  content: "\e93e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automation .path9:before {
  content: "\e93f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automation .path10:before {
  content: "\e940";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automation .path11:before {
  content: "\e941";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-automation .path12:before {
  content: "\e942";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path1:before {
  content: "\e943";
  color: rgb(230, 231, 232);
}
.icon-autonomous-car .path2:before {
  content: "\e944";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-autonomous-car .path3:before {
  content: "\e945";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-autonomous-car .path4:before {
  content: "\e946";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-autonomous-car .path5:before {
  content: "\e947";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-autonomous-car .path6:before {
  content: "\e948";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-autonomous-car .path7:before {
  content: "\e949";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path8:before {
  content: "\e94a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path9:before {
  content: "\e94b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-autonomous-car .path10:before {
  content: "\e94c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-autonomous-car .path11:before {
  content: "\e94d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path12:before {
  content: "\e94e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path13:before {
  content: "\e94f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path14:before {
  content: "\e950";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-autonomous-car .path15:before {
  content: "\e951";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-autonomous-car .path16:before {
  content: "\e952";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-autonomous-car .path17:before {
  content: "\e953";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-autonomous-car .path18:before {
  content: "\e954";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-autonomous-car .path19:before {
  content: "\e955";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-autonomous-car .path20:before {
  content: "\e956";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-autonomous-car .path21:before {
  content: "\e957";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path22:before {
  content: "\e958";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path23:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path24:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path25:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path26:before {
  content: "\e95c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path27:before {
  content: "\e95d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-autonomous-car .path28:before {
  content: "\e95e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bike-charging-station .path1:before {
  content: "\e95f";
  color: rgb(230, 231, 232);
}
.icon-bike-charging-station .path2:before {
  content: "\e960";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-bike-charging-station .path3:before {
  content: "\e961";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bike-charging-station .path4:before {
  content: "\e962";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bike-charging-station .path5:before {
  content: "\e963";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bike-charging-station .path6:before {
  content: "\e964";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bike-charging-station .path7:before {
  content: "\e965";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bike-charging-station .path8:before {
  content: "\e966";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bike-charging-station .path9:before {
  content: "\e967";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bike-charging-station .path10:before {
  content: "\e968";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-bike-charging-station .path11:before {
  content: "\e969";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bike-charging-station .path12:before {
  content: "\e96a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bike-charging-station .path13:before {
  content: "\e96b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-bike-charging-station .path14:before {
  content: "\e96c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-bike-charging-station .path15:before {
  content: "\e96d";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-bike-charging-station .path16:before {
  content: "\e96e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bike-charging-station .path17:before {
  content: "\e96f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bike-charging-station .path18:before {
  content: "\e970";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bike-charging-station .path19:before {
  content: "\e971";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path1:before {
  content: "\e972";
  color: rgb(230, 231, 232);
}
.icon-bluetooth-speaker .path2:before {
  content: "\e973";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-bluetooth-speaker .path3:before {
  content: "\e974";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-bluetooth-speaker .path4:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-bluetooth-speaker .path5:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-bluetooth-speaker .path6:before {
  content: "\e977";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-bluetooth-speaker .path7:before {
  content: "\e978";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-bluetooth-speaker .path8:before {
  content: "\e979";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-bluetooth-speaker .path9:before {
  content: "\e97a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bluetooth-speaker .path10:before {
  content: "\e97b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-bluetooth-speaker .path11:before {
  content: "\e97c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-bluetooth-speaker .path12:before {
  content: "\e97d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-bluetooth-speaker .path13:before {
  content: "\e97e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path14:before {
  content: "\e97f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path15:before {
  content: "\e980";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path16:before {
  content: "\e981";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path17:before {
  content: "\e982";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path18:before {
  content: "\e983";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path19:before {
  content: "\e984";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path20:before {
  content: "\e985";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path21:before {
  content: "\e986";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-bluetooth-speaker .path22:before {
  content: "\e987";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cctv .path1:before {
  content: "\e988";
  color: rgb(230, 231, 232);
}
.icon-cctv .path2:before {
  content: "\e989";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cctv .path3:before {
  content: "\e98a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cctv .path4:before {
  content: "\e98b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cctv .path5:before {
  content: "\e98c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cctv .path6:before {
  content: "\e98d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cctv .path7:before {
  content: "\e98e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cctv .path8:before {
  content: "\e98f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cctv .path9:before {
  content: "\e990";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cctv .path10:before {
  content: "\e991";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cctv .path11:before {
  content: "\e992";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cctv .path12:before {
  content: "\e993";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cctv .path13:before {
  content: "\e994";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cctv .path14:before {
  content: "\e995";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cctv .path15:before {
  content: "\e996";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cd-player .path1:before {
  content: "\e997";
  color: rgb(230, 231, 232);
}
.icon-cd-player .path2:before {
  content: "\e998";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-cd-player .path3:before {
  content: "\e999";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cd-player .path4:before {
  content: "\e99a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cd-player .path5:before {
  content: "\e99b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cd-player .path6:before {
  content: "\e99c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cd-player .path7:before {
  content: "\e99d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-cd-player .path8:before {
  content: "\e99e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cd-player .path9:before {
  content: "\e99f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cd-player .path10:before {
  content: "\e9a0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cd-player .path11:before {
  content: "\e9a1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cd-player .path12:before {
  content: "\e9a2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cd-player .path13:before {
  content: "\e9a3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cd-player .path14:before {
  content: "\e9a4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cd-player .path15:before {
  content: "\e9a5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cloud-computing .path1:before {
  content: "\e9a6";
  color: rgb(230, 231, 232);
}
.icon-cloud-computing .path2:before {
  content: "\e9a7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cloud-computing .path3:before {
  content: "\e9a8";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cloud-computing .path4:before {
  content: "\e9a9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cloud-computing .path5:before {
  content: "\e9aa";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cloud-computing .path6:before {
  content: "\e9ab";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cloud-computing .path7:before {
  content: "\e9ac";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cloud-computing .path8:before {
  content: "\e9ad";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cloud-computing .path9:before {
  content: "\e9ae";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cloud-computing .path10:before {
  content: "\e9af";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path1:before {
  content: "\e9b0";
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path2:before {
  content: "\e9b1";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path3:before {
  content: "\e9b2";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path4:before {
  content: "\e9b3";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path5:before {
  content: "\e9b4";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path6:before {
  content: "\e9b5";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path7:before {
  content: "\e9b6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path8:before {
  content: "\e9b7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path9:before {
  content: "\e9b8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path10:before {
  content: "\e9b9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path11:before {
  content: "\e9ba";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path12:before {
  content: "\e9bb";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-collaborative-innovation-network .path13:before {
  content: "\e9bc";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-collaborative-innovation-network .path14:before {
  content: "\e9bd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path15:before {
  content: "\e9be";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-collaborative-innovation-network .path16:before {
  content: "\e9bf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path17:before {
  content: "\e9c0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path18:before {
  content: "\e9c1";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-collaborative-innovation-network .path19:before {
  content: "\e9c2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path20:before {
  content: "\e9c3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-collaborative-innovation-network .path21:before {
  content: "\e9c4";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-collaborative-innovation-network .path22:before {
  content: "\e9c5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path23:before {
  content: "\e9c6";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-collaborative-innovation-network .path24:before {
  content: "\e9c7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path25:before {
  content: "\e9c8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path26:before {
  content: "\e9c9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-collaborative-innovation-network .path27:before {
  content: "\e9ca";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path28:before {
  content: "\e9cb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path29:before {
  content: "\e9cc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path30:before {
  content: "\e9cd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path31:before {
  content: "\e9ce";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path32:before {
  content: "\e9cf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path33:before {
  content: "\e9d0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path34:before {
  content: "\e9d1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path35:before {
  content: "\e9d2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path36:before {
  content: "\e9d3";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-collaborative-innovation-network .path37:before {
  content: "\e9d4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path38:before {
  content: "\e9d5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path39:before {
  content: "\e9d6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path40:before {
  content: "\e9d7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path41:before {
  content: "\e9d8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-collaborative-innovation-network .path42:before {
  content: "\e9d9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path1:before {
  content: "\e9da";
  color: rgb(230, 231, 232);
}
.icon-communication .path2:before {
  content: "\e9db";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path3:before {
  content: "\e9dc";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path4:before {
  content: "\e9dd";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path5:before {
  content: "\e9de";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path6:before {
  content: "\e9df";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path7:before {
  content: "\e9e0";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-communication .path8:before {
  content: "\e9e1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-communication .path9:before {
  content: "\e9e2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-communication .path10:before {
  content: "\e9e3";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-communication .path11:before {
  content: "\e9e4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-communication .path12:before {
  content: "\e9e5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-communication .path13:before {
  content: "\e9e6";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path14:before {
  content: "\e9e7";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path15:before {
  content: "\e9e8";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path16:before {
  content: "\e9e9";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path17:before {
  content: "\e9ea";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path18:before {
  content: "\e9eb";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-communication .path19:before {
  content: "\e9ec";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-communication .path20:before {
  content: "\e9ed";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path21:before {
  content: "\e9ee";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path22:before {
  content: "\e9ef";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path23:before {
  content: "\e9f0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path24:before {
  content: "\e9f1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path25:before {
  content: "\e9f2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path26:before {
  content: "\e9f3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-communication .path27:before {
  content: "\e9f4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path1:before {
  content: "\e9f5";
  color: rgb(230, 231, 232);
}
.icon-connected-car .path2:before {
  content: "\e9f6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path3:before {
  content: "\e9f7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path4:before {
  content: "\e9f8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path5:before {
  content: "\e9f9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path6:before {
  content: "\e9fa";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path7:before {
  content: "\e9fb";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path8:before {
  content: "\e9fc";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path9:before {
  content: "\e9fd";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path10:before {
  content: "\e9fe";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path11:before {
  content: "\e9ff";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-connected-car .path12:before {
  content: "\ea00";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path13:before {
  content: "\ea01";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-connected-car .path14:before {
  content: "\ea02";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path15:before {
  content: "\ea03";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path16:before {
  content: "\ea04";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path17:before {
  content: "\ea05";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-connected-car .path18:before {
  content: "\ea06";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path19:before {
  content: "\ea07";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path20:before {
  content: "\ea08";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path21:before {
  content: "\ea09";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path22:before {
  content: "\ea0a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path23:before {
  content: "\ea0b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path24:before {
  content: "\ea0c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-connected-car .path25:before {
  content: "\ea0d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path26:before {
  content: "\ea0e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path27:before {
  content: "\ea0f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path28:before {
  content: "\ea10";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path29:before {
  content: "\ea11";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path30:before {
  content: "\ea12";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-connected-car .path31:before {
  content: "\ea13";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-connected-car .path32:before {
  content: "\ea14";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path33:before {
  content: "\ea15";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path34:before {
  content: "\ea16";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path35:before {
  content: "\ea17";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path36:before {
  content: "\ea18";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path37:before {
  content: "\ea19";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-connected-car .path38:before {
  content: "\ea1a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path39:before {
  content: "\ea1b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path40:before {
  content: "\ea1c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path41:before {
  content: "\ea1d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path42:before {
  content: "\ea1e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path43:before {
  content: "\ea1f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path44:before {
  content: "\ea20";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path45:before {
  content: "\ea21";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path46:before {
  content: "\ea22";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path47:before {
  content: "\ea23";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path48:before {
  content: "\ea24";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path49:before {
  content: "\ea25";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path50:before {
  content: "\ea26";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path51:before {
  content: "\ea27";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path52:before {
  content: "\ea28";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path53:before {
  content: "\ea29";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path54:before {
  content: "\ea2a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path55:before {
  content: "\ea2b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-connected-car .path56:before {
  content: "\ea2c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cpu .path1:before {
  content: "\ea2d";
  color: rgb(230, 231, 232);
}
.icon-cpu .path2:before {
  content: "\ea2e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cpu .path3:before {
  content: "\ea2f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cpu .path4:before {
  content: "\ea30";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-cpu .path5:before {
  content: "\ea31";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path6:before {
  content: "\ea32";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path7:before {
  content: "\ea33";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path8:before {
  content: "\ea34";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path9:before {
  content: "\ea35";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path10:before {
  content: "\ea36";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-cpu .path11:before {
  content: "\ea37";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cpu .path12:before {
  content: "\ea38";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-cpu .path13:before {
  content: "\ea39";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-cpu .path14:before {
  content: "\ea3a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cpu .path15:before {
  content: "\ea3b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cpu .path16:before {
  content: "\ea3c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cpu .path17:before {
  content: "\ea3d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-cpu .path18:before {
  content: "\ea3e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-cpu .path19:before {
  content: "\ea3f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-cpu .path20:before {
  content: "\ea40";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-devices .path1:before {
  content: "\ea41";
  color: rgb(230, 231, 232);
}
.icon-devices .path2:before {
  content: "\ea42";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path3:before {
  content: "\ea43";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-devices .path4:before {
  content: "\ea44";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-devices .path5:before {
  content: "\ea45";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-devices .path6:before {
  content: "\ea46";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-devices .path7:before {
  content: "\ea47";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-devices .path8:before {
  content: "\ea48";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-devices .path9:before {
  content: "\ea49";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-devices .path10:before {
  content: "\ea4a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-devices .path11:before {
  content: "\ea4b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-devices .path12:before {
  content: "\ea4c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path13:before {
  content: "\ea4d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path14:before {
  content: "\ea4e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path15:before {
  content: "\ea4f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path16:before {
  content: "\ea50";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path17:before {
  content: "\ea51";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path18:before {
  content: "\ea52";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path19:before {
  content: "\ea53";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path20:before {
  content: "\ea54";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path21:before {
  content: "\ea55";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path22:before {
  content: "\ea56";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path23:before {
  content: "\ea57";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path24:before {
  content: "\ea58";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path25:before {
  content: "\ea59";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path26:before {
  content: "\ea5a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-devices .path27:before {
  content: "\ea5b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-digital-camera .path1:before {
  content: "\ea5c";
  color: rgb(230, 231, 232);
}
.icon-digital-camera .path2:before {
  content: "\ea5d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-digital-camera .path3:before {
  content: "\ea5e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-digital-camera .path4:before {
  content: "\ea5f";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-digital-camera .path5:before {
  content: "\ea60";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-digital-camera .path6:before {
  content: "\ea61";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-digital-camera .path7:before {
  content: "\ea62";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-digital-camera .path8:before {
  content: "\ea63";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-digital-camera .path9:before {
  content: "\ea64";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-digital-camera .path10:before {
  content: "\ea65";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-digital-camera .path11:before {
  content: "\ea66";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-digital-camera .path12:before {
  content: "\ea67";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-digital-camera .path13:before {
  content: "\ea68";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-disaster-management .path1:before {
  content: "\ea69";
  color: rgb(230, 231, 232);
}
.icon-disaster-management .path2:before {
  content: "\ea6a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-disaster-management .path3:before {
  content: "\ea6b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-disaster-management .path4:before {
  content: "\ea6c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-disaster-management .path5:before {
  content: "\ea6d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-disaster-management .path6:before {
  content: "\ea6e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-disaster-management .path7:before {
  content: "\ea6f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-disaster-management .path8:before {
  content: "\ea70";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-disaster-management .path9:before {
  content: "\ea71";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-disaster-management .path10:before {
  content: "\ea72";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-disaster-management .path11:before {
  content: "\ea73";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-disaster-management .path12:before {
  content: "\ea74";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-disaster-management .path13:before {
  content: "\ea75";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-disaster-management .path14:before {
  content: "\ea76";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-disaster-management .path15:before {
  content: "\ea77";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-disaster-management .path16:before {
  content: "\ea78";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-disaster-management .path17:before {
  content: "\ea79";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-disaster-management .path18:before {
  content: "\ea7a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-disaster-management .path19:before {
  content: "\ea7b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-disaster-management .path20:before {
  content: "\ea7c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-delivery .path1:before {
  content: "\ea7d";
  color: rgb(230, 231, 232);
}
.icon-drone-delivery .path2:before {
  content: "\ea7e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path3:before {
  content: "\ea7f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path4:before {
  content: "\ea80";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-drone-delivery .path5:before {
  content: "\ea81";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path6:before {
  content: "\ea82";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path7:before {
  content: "\ea83";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path8:before {
  content: "\ea84";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-delivery .path9:before {
  content: "\ea85";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-drone-delivery .path10:before {
  content: "\ea86";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-delivery .path11:before {
  content: "\ea87";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-delivery .path12:before {
  content: "\ea88";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path13:before {
  content: "\ea89";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path14:before {
  content: "\ea8a";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-drone-delivery .path15:before {
  content: "\ea8b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path16:before {
  content: "\ea8c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path17:before {
  content: "\ea8d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path18:before {
  content: "\ea8e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path19:before {
  content: "\ea8f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-delivery .path20:before {
  content: "\ea90";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-delivery .path21:before {
  content: "\ea91";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path22:before {
  content: "\ea92";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-delivery .path23:before {
  content: "\ea93";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-delivery .path24:before {
  content: "\ea94";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-delivery .path25:before {
  content: "\ea95";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-delivery .path26:before {
  content: "\ea96";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path1:before {
  content: "\ea97";
  color: rgb(230, 231, 232);
}
.icon-drone-surveillance .path2:before {
  content: "\ea98";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path3:before {
  content: "\ea99";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path4:before {
  content: "\ea9a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path5:before {
  content: "\ea9b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path6:before {
  content: "\ea9c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path7:before {
  content: "\ea9d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path8:before {
  content: "\ea9e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path9:before {
  content: "\ea9f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path10:before {
  content: "\eaa0";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path11:before {
  content: "\eaa1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path12:before {
  content: "\eaa2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-drone-surveillance .path13:before {
  content: "\eaa3";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-drone-surveillance .path14:before {
  content: "\eaa4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path15:before {
  content: "\eaa5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path16:before {
  content: "\eaa6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-drone-surveillance .path17:before {
  content: "\eaa7";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path18:before {
  content: "\eaa8";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path19:before {
  content: "\eaa9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-drone-surveillance .path20:before {
  content: "\eaaa";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path21:before {
  content: "\eaab";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-drone-surveillance .path22:before {
  content: "\eaac";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-drone-surveillance .path23:before {
  content: "\eaad";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path24:before {
  content: "\eaae";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-drone-surveillance .path25:before {
  content: "\eaaf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path26:before {
  content: "\eab0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path27:before {
  content: "\eab1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path28:before {
  content: "\eab2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path29:before {
  content: "\eab3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path30:before {
  content: "\eab4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path31:before {
  content: "\eab5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path32:before {
  content: "\eab6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path33:before {
  content: "\eab7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path34:before {
  content: "\eab8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path35:before {
  content: "\eab9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path36:before {
  content: "\eaba";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path37:before {
  content: "\eabb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path38:before {
  content: "\eabc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-drone-surveillance .path39:before {
  content: "\eabd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path1:before {
  content: "\eabe";
  color: rgb(230, 231, 232);
}
.icon-ebook-reader .path2:before {
  content: "\eabf";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-ebook-reader .path3:before {
  content: "\eac0";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-ebook-reader .path4:before {
  content: "\eac1";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-ebook-reader .path5:before {
  content: "\eac2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-ebook-reader .path6:before {
  content: "\eac3";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-ebook-reader .path7:before {
  content: "\eac4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-ebook-reader .path8:before {
  content: "\eac5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-ebook-reader .path9:before {
  content: "\eac6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path10:before {
  content: "\eac7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path11:before {
  content: "\eac8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path12:before {
  content: "\eac9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path13:before {
  content: "\eaca";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path14:before {
  content: "\eacb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path15:before {
  content: "\eacc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path16:before {
  content: "\eacd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-ebook-reader .path17:before {
  content: "\eace";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eco-friendly .path1:before {
  content: "\eacf";
  color: rgb(230, 231, 232);
}
.icon-eco-friendly .path2:before {
  content: "\ead0";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-eco-friendly .path3:before {
  content: "\ead1";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-eco-friendly .path4:before {
  content: "\ead2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eco-friendly .path5:before {
  content: "\ead3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eco-friendly .path6:before {
  content: "\ead4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-eco-friendly .path7:before {
  content: "\ead5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eco-friendly .path8:before {
  content: "\ead6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eco-friendly .path9:before {
  content: "\ead7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eco-friendly .path10:before {
  content: "\ead8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path1:before {
  content: "\ead9";
  color: rgb(230, 231, 232);
}
.icon-electric-car .path2:before {
  content: "\eada";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path3:before {
  content: "\eadb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path4:before {
  content: "\eadc";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-electric-car .path5:before {
  content: "\eadd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path6:before {
  content: "\eade";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-electric-car .path7:before {
  content: "\eadf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path8:before {
  content: "\eae0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path9:before {
  content: "\eae1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path10:before {
  content: "\eae2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path11:before {
  content: "\eae3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path12:before {
  content: "\eae4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-electric-car .path13:before {
  content: "\eae5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path14:before {
  content: "\eae6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-electric-car .path15:before {
  content: "\eae7";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-electric-car .path16:before {
  content: "\eae8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path17:before {
  content: "\eae9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-electric-car .path18:before {
  content: "\eaea";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path19:before {
  content: "\eaeb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path20:before {
  content: "\eaec";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path21:before {
  content: "\eaed";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path22:before {
  content: "\eaee";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path23:before {
  content: "\eaef";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-electric-car .path24:before {
  content: "\eaf0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path25:before {
  content: "\eaf1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path26:before {
  content: "\eaf2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-electric-car .path27:before {
  content: "\eaf3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-electric-car .path28:before {
  content: "\eaf4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path29:before {
  content: "\eaf5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path30:before {
  content: "\eaf6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-electric-car .path31:before {
  content: "\eaf7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-electric-car .path32:before {
  content: "\eaf8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-energy-consumption .path1:before {
  content: "\eaf9";
  color: rgb(230, 231, 232);
}
.icon-energy-consumption .path2:before {
  content: "\eafa";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-energy-consumption .path3:before {
  content: "\eafb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-energy-consumption .path4:before {
  content: "\eafc";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-energy-consumption .path5:before {
  content: "\eafd";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-energy-consumption .path6:before {
  content: "\eafe";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-energy-consumption .path7:before {
  content: "\eaff";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-energy-consumption .path8:before {
  content: "\eb00";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-energy-consumption .path9:before {
  content: "\eb01";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-energy-consumption .path10:before {
  content: "\eb02";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-energy-consumption .path11:before {
  content: "\eb03";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path1:before {
  content: "\eb04";
  color: rgb(230, 231, 232);
}
.icon-environmental-monitoring .path2:before {
  content: "\eb05";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-environmental-monitoring .path3:before {
  content: "\eb06";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-environmental-monitoring .path4:before {
  content: "\eb07";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-environmental-monitoring .path5:before {
  content: "\eb08";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-environmental-monitoring .path6:before {
  content: "\eb09";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-environmental-monitoring .path7:before {
  content: "\eb0a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path8:before {
  content: "\eb0b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-environmental-monitoring .path9:before {
  content: "\eb0c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path10:before {
  content: "\eb0d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path11:before {
  content: "\eb0e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path12:before {
  content: "\eb0f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-environmental-monitoring .path13:before {
  content: "\eb10";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path14:before {
  content: "\eb11";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path15:before {
  content: "\eb12";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path16:before {
  content: "\eb13";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path17:before {
  content: "\eb14";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-environmental-monitoring .path18:before {
  content: "\eb15";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path19:before {
  content: "\eb16";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-environmental-monitoring .path20:before {
  content: "\eb17";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-environmental-monitoring .path21:before {
  content: "\eb18";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-environmental-monitoring .path22:before {
  content: "\eb19";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-environmental-monitoring .path23:before {
  content: "\eb1a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-environmental-monitoring .path24:before {
  content: "\eb1b";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-environmental-monitoring .path25:before {
  content: "\eb1c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-environmental-monitoring .path26:before {
  content: "\eb1d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-environmental-monitoring .path27:before {
  content: "\eb1e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-environmental-monitoring .path28:before {
  content: "\eb1f";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-environmental-monitoring .path29:before {
  content: "\eb20";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path30:before {
  content: "\eb21";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path31:before {
  content: "\eb22";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path32:before {
  content: "\eb23";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-environmental-monitoring .path33:before {
  content: "\eb24";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path1:before {
  content: "\eb25";
  color: rgb(230, 231, 232);
}
.icon-eye-scanning .path2:before {
  content: "\eb26";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-eye-scanning .path3:before {
  content: "\eb27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eye-scanning .path4:before {
  content: "\eb28";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-eye-scanning .path5:before {
  content: "\eb29";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eye-scanning .path6:before {
  content: "\eb2a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-eye-scanning .path7:before {
  content: "\eb2b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-eye-scanning .path8:before {
  content: "\eb2c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-eye-scanning .path9:before {
  content: "\eb2d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-eye-scanning .path10:before {
  content: "\eb2e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eye-scanning .path11:before {
  content: "\eb2f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eye-scanning .path12:before {
  content: "\eb30";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eye-scanning .path13:before {
  content: "\eb31";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eye-scanning .path14:before {
  content: "\eb32";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-eye-scanning .path15:before {
  content: "\eb33";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path16:before {
  content: "\eb34";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path17:before {
  content: "\eb35";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path18:before {
  content: "\eb36";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path19:before {
  content: "\eb37";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path20:before {
  content: "\eb38";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path21:before {
  content: "\eb39";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path22:before {
  content: "\eb3a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path23:before {
  content: "\eb3b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path24:before {
  content: "\eb3c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-eye-scanning .path25:before {
  content: "\eb3d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path1:before {
  content: "\eb3e";
  color: rgb(230, 231, 232);
}
.icon-fingerprint .path2:before {
  content: "\eb3f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-fingerprint .path3:before {
  content: "\eb40";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-fingerprint .path4:before {
  content: "\eb41";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-fingerprint .path5:before {
  content: "\eb42";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-fingerprint .path6:before {
  content: "\eb43";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-fingerprint .path7:before {
  content: "\eb44";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-fingerprint .path8:before {
  content: "\eb45";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-fingerprint .path9:before {
  content: "\eb46";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-fingerprint .path10:before {
  content: "\eb47";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fingerprint .path11:before {
  content: "\eb48";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-fingerprint .path12:before {
  content: "\eb49";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path13:before {
  content: "\eb4a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path14:before {
  content: "\eb4b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path15:before {
  content: "\eb4c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path16:before {
  content: "\eb4d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path17:before {
  content: "\eb4e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path18:before {
  content: "\eb4f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path19:before {
  content: "\eb50";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path20:before {
  content: "\eb51";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path21:before {
  content: "\eb52";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path22:before {
  content: "\eb53";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fingerprint .path23:before {
  content: "\eb54";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fitness-tracker .path1:before {
  content: "\eb55";
  color: rgb(230, 231, 232);
}
.icon-fitness-tracker .path2:before {
  content: "\eb56";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-fitness-tracker .path3:before {
  content: "\eb57";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fitness-tracker .path4:before {
  content: "\eb58";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-fitness-tracker .path5:before {
  content: "\eb59";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-fitness-tracker .path6:before {
  content: "\eb5a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-fitness-tracker .path7:before {
  content: "\eb5b";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-fitness-tracker .path8:before {
  content: "\eb5c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-fitness-tracker .path9:before {
  content: "\eb5d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-fitness-tracker .path10:before {
  content: "\eb5e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fitness-tracker .path11:before {
  content: "\eb5f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-fitness-tracker .path12:before {
  content: "\eb60";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-gameplay-sharing .path1:before {
  content: "\eb61";
  color: rgb(230, 231, 232);
}
.icon-gameplay-sharing .path2:before {
  content: "\eb62";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-gameplay-sharing .path3:before {
  content: "\eb63";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path4:before {
  content: "\eb64";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-gameplay-sharing .path5:before {
  content: "\eb65";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path6:before {
  content: "\eb66";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path7:before {
  content: "\eb67";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path8:before {
  content: "\eb68";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path9:before {
  content: "\eb69";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path10:before {
  content: "\eb6a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-gameplay-sharing .path11:before {
  content: "\eb6b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path12:before {
  content: "\eb6c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path13:before {
  content: "\eb6d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path14:before {
  content: "\eb6e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path15:before {
  content: "\eb6f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gameplay-sharing .path16:before {
  content: "\eb70";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-gameplay-sharing .path17:before {
  content: "\eb71";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-gameplay-sharing .path18:before {
  content: "\eb72";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-gameplay-sharing .path19:before {
  content: "\eb73";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-gaming-console .path1:before {
  content: "\eb74";
  color: rgb(230, 231, 232);
}
.icon-gaming-console .path2:before {
  content: "\eb75";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path3:before {
  content: "\eb76";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-gaming-console .path4:before {
  content: "\eb77";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path5:before {
  content: "\eb78";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path6:before {
  content: "\eb79";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path7:before {
  content: "\eb7a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path8:before {
  content: "\eb7b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-gaming-console .path9:before {
  content: "\eb7c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-gaming-console .path10:before {
  content: "\eb7d";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-gaming-console .path11:before {
  content: "\eb7e";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-gaming-console .path12:before {
  content: "\eb7f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-gaming-console .path13:before {
  content: "\eb80";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-gaming-console .path14:before {
  content: "\eb81";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-gaming-console .path15:before {
  content: "\eb82";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path1:before {
  content: "\eb83";
  color: rgb(230, 231, 232);
}
.icon-handheld-console .path2:before {
  content: "\eb84";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-handheld-console .path3:before {
  content: "\eb85";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-handheld-console .path4:before {
  content: "\eb86";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-handheld-console .path5:before {
  content: "\eb87";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-handheld-console .path6:before {
  content: "\eb88";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-handheld-console .path7:before {
  content: "\eb89";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-handheld-console .path8:before {
  content: "\eb8a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-handheld-console .path9:before {
  content: "\eb8b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-handheld-console .path10:before {
  content: "\eb8c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path11:before {
  content: "\eb8d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path12:before {
  content: "\eb8e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path13:before {
  content: "\eb8f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path14:before {
  content: "\eb90";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path15:before {
  content: "\eb91";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path16:before {
  content: "\eb92";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-handheld-console .path17:before {
  content: "\eb93";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path1:before {
  content: "\eb94";
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path2:before {
  content: "\eb95";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path3:before {
  content: "\eb96";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path4:before {
  content: "\eb97";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path5:before {
  content: "\eb98";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path6:before {
  content: "\eb99";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path7:before {
  content: "\eb9a";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path8:before {
  content: "\eb9b";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path9:before {
  content: "\eb9c";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path10:before {
  content: "\eb9d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-household-carbon-footprint .path11:before {
  content: "\eb9e";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-household-carbon-footprint .path12:before {
  content: "\eb9f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path13:before {
  content: "\eba0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path14:before {
  content: "\eba1";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path15:before {
  content: "\eba2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path16:before {
  content: "\eba3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path17:before {
  content: "\eba4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path18:before {
  content: "\eba5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-household-carbon-footprint .path19:before {
  content: "\eba6";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path20:before {
  content: "\eba7";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-household-carbon-footprint .path21:before {
  content: "\eba8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path22:before {
  content: "\eba9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path23:before {
  content: "\ebaa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path24:before {
  content: "\ebab";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path25:before {
  content: "\ebac";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path26:before {
  content: "\ebad";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path27:before {
  content: "\ebae";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path28:before {
  content: "\ebaf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path29:before {
  content: "\ebb0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path30:before {
  content: "\ebb1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-household-carbon-footprint .path31:before {
  content: "\ebb2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path1:before {
  content: "\ebb3";
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path2:before {
  content: "\ebb4";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path3:before {
  content: "\ebb5";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path4:before {
  content: "\ebb6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path5:before {
  content: "\ebb7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path6:before {
  content: "\ebb8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path7:before {
  content: "\ebb9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path8:before {
  content: "\ebba";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path9:before {
  content: "\ebbb";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-energy-control .path10:before {
  content: "\ebbc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-intelligent-energy-control .path11:before {
  content: "\ebbd";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-intelligent-energy-control .path12:before {
  content: "\ebbe";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-energy-control .path13:before {
  content: "\ebbf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-intelligent-energy-control .path14:before {
  content: "\ebc0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-energy-control .path15:before {
  content: "\ebc1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path16:before {
  content: "\ebc2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path17:before {
  content: "\ebc3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path18:before {
  content: "\ebc4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path19:before {
  content: "\ebc5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path20:before {
  content: "\ebc6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path21:before {
  content: "\ebc7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path22:before {
  content: "\ebc8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path23:before {
  content: "\ebc9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path24:before {
  content: "\ebca";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path25:before {
  content: "\ebcb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-energy-control .path26:before {
  content: "\ebcc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path1:before {
  content: "\ebcd";
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path2:before {
  content: "\ebce";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path3:before {
  content: "\ebcf";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path4:before {
  content: "\ebd0";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path5:before {
  content: "\ebd1";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path6:before {
  content: "\ebd2";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path7:before {
  content: "\ebd3";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path8:before {
  content: "\ebd4";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-power .path9:before {
  content: "\ebd5";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-power .path10:before {
  content: "\ebd6";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-intelligent-power .path11:before {
  content: "\ebd7";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-intelligent-power .path12:before {
  content: "\ebd8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-intelligent-power .path13:before {
  content: "\ebd9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-power .path14:before {
  content: "\ebda";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path15:before {
  content: "\ebdb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path16:before {
  content: "\ebdc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path17:before {
  content: "\ebdd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path18:before {
  content: "\ebde";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path19:before {
  content: "\ebdf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path20:before {
  content: "\ebe0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path21:before {
  content: "\ebe1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path22:before {
  content: "\ebe2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-power .path23:before {
  content: "\ebe3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path1:before {
  content: "\ebe4";
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path2:before {
  content: "\ebe5";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path3:before {
  content: "\ebe6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path4:before {
  content: "\ebe7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path5:before {
  content: "\ebe8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path6:before {
  content: "\ebe9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path7:before {
  content: "\ebea";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path8:before {
  content: "\ebeb";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-intelligent-urbanism .path9:before {
  content: "\ebec";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-intelligent-urbanism .path10:before {
  content: "\ebed";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path11:before {
  content: "\ebee";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path12:before {
  content: "\ebef";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path13:before {
  content: "\ebf0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path14:before {
  content: "\ebf1";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-intelligent-urbanism .path15:before {
  content: "\ebf2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path16:before {
  content: "\ebf3";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path17:before {
  content: "\ebf4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path18:before {
  content: "\ebf5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path19:before {
  content: "\ebf6";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path20:before {
  content: "\ebf7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path21:before {
  content: "\ebf8";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path22:before {
  content: "\ebf9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path23:before {
  content: "\ebfa";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path24:before {
  content: "\ebfb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path25:before {
  content: "\ebfc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path26:before {
  content: "\ebfd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path27:before {
  content: "\ebfe";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path28:before {
  content: "\ebff";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path29:before {
  content: "\ec00";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path30:before {
  content: "\ec01";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path31:before {
  content: "\ec02";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-intelligent-urbanism .path32:before {
  content: "\ec03";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-intelligent-urbanism .path33:before {
  content: "\ec04";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-intelligent-urbanism .path34:before {
  content: "\ec05";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-intelligent-urbanism .path35:before {
  content: "\ec06";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-intelligent-urbanism .path36:before {
  content: "\ec07";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path37:before {
  content: "\ec08";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path38:before {
  content: "\ec09";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path39:before {
  content: "\ec0a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path40:before {
  content: "\ec0b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path41:before {
  content: "\ec0c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path42:before {
  content: "\ec0d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path43:before {
  content: "\ec0e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path44:before {
  content: "\ec0f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path45:before {
  content: "\ec10";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path46:before {
  content: "\ec11";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-intelligent-urbanism .path47:before {
  content: "\ec12";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path48:before {
  content: "\ec13";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path49:before {
  content: "\ec14";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path50:before {
  content: "\ec15";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path51:before {
  content: "\ec16";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path52:before {
  content: "\ec17";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path53:before {
  content: "\ec18";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path54:before {
  content: "\ec19";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-intelligent-urbanism .path55:before {
  content: "\ec1a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-internet .path1:before {
  content: "\ec1b";
  color: rgb(230, 231, 232);
}
.icon-internet .path2:before {
  content: "\ec1c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-internet .path3:before {
  content: "\ec1d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-internet .path4:before {
  content: "\ec1e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-internet .path5:before {
  content: "\ec1f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-internet .path6:before {
  content: "\ec20";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-internet .path7:before {
  content: "\ec21";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-management-system .path1:before {
  content: "\ec22";
  color: rgb(230, 231, 232);
}
.icon-knowledge-management-system .path2:before {
  content: "\ec23";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-management-system .path3:before {
  content: "\ec24";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-management-system .path4:before {
  content: "\ec25";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-management-system .path5:before {
  content: "\ec26";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-knowledge-management-system .path6:before {
  content: "\ec27";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-management-system .path7:before {
  content: "\ec28";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-knowledge-management-system .path8:before {
  content: "\ec29";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-management-system .path9:before {
  content: "\ec2a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-management-system .path10:before {
  content: "\ec2b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-knowledge-management-system .path11:before {
  content: "\ec2c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-management-system .path12:before {
  content: "\ec2d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-management-system .path13:before {
  content: "\ec2e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-knowledge-management-system .path14:before {
  content: "\ec2f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-management-system .path15:before {
  content: "\ec30";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-knowledge-management-system .path16:before {
  content: "\ec31";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-management-system .path17:before {
  content: "\ec32";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-management-system .path18:before {
  content: "\ec33";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-management-system .path19:before {
  content: "\ec34";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-management-system .path20:before {
  content: "\ec35";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-management-system .path21:before {
  content: "\ec36";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-management-system .path22:before {
  content: "\ec37";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-management-system .path23:before {
  content: "\ec38";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-management-system .path24:before {
  content: "\ec39";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path1:before {
  content: "\ec3a";
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path2:before {
  content: "\ec3b";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path3:before {
  content: "\ec3c";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path4:before {
  content: "\ec3d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path5:before {
  content: "\ec3e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path6:before {
  content: "\ec3f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-knowledge-worker .path7:before {
  content: "\ec40";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-worker .path8:before {
  content: "\ec41";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-worker .path9:before {
  content: "\ec42";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-worker .path10:before {
  content: "\ec43";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-worker .path11:before {
  content: "\ec44";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-knowledge-worker .path12:before {
  content: "\ec45";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-knowledge-worker .path13:before {
  content: "\ec46";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-knowledge-worker .path14:before {
  content: "\ec47";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-knowledge-worker .path15:before {
  content: "\ec48";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-knowledge-worker .path16:before {
  content: "\ec49";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-worker .path17:before {
  content: "\ec4a";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-knowledge-worker .path18:before {
  content: "\ec4b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-worker .path19:before {
  content: "\ec4c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-knowledge-worker .path20:before {
  content: "\ec4d";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-knowledge-worker .path21:before {
  content: "\ec4e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-knowledge-worker .path22:before {
  content: "\ec4f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-knowledge-worker .path23:before {
  content: "\ec50";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path24:before {
  content: "\ec51";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path25:before {
  content: "\ec52";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path26:before {
  content: "\ec53";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path27:before {
  content: "\ec54";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path28:before {
  content: "\ec55";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path29:before {
  content: "\ec56";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path30:before {
  content: "\ec57";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path31:before {
  content: "\ec58";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path32:before {
  content: "\ec59";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path33:before {
  content: "\ec5a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path34:before {
  content: "\ec5b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path35:before {
  content: "\ec5c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-knowledge-worker .path36:before {
  content: "\ec5d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobile-payment .path1:before {
  content: "\ec5e";
  color: rgb(230, 231, 232);
}
.icon-mobile-payment .path2:before {
  content: "\ec5f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mobile-payment .path3:before {
  content: "\ec60";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-mobile-payment .path4:before {
  content: "\ec61";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-mobile-payment .path5:before {
  content: "\ec62";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mobile-payment .path6:before {
  content: "\ec63";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mobile-payment .path7:before {
  content: "\ec64";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mobile-payment .path8:before {
  content: "\ec65";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mobile-payment .path9:before {
  content: "\ec66";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mobile-payment .path10:before {
  content: "\ec67";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobile-payment .path11:before {
  content: "\ec68";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobile-payment .path12:before {
  content: "\ec69";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mobility .path1:before {
  content: "\ec6a";
  color: rgb(230, 231, 232);
}
.icon-mobility .path2:before {
  content: "\ec6b";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-mobility .path3:before {
  content: "\ec6c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mobility .path4:before {
  content: "\ec6d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mobility .path5:before {
  content: "\ec6e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mobility .path6:before {
  content: "\ec6f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobility .path7:before {
  content: "\ec70";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobility .path8:before {
  content: "\ec71";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mobility .path9:before {
  content: "\ec72";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path1:before {
  content: "\ec73";
  color: rgb(230, 231, 232);
}
.icon-motion-sensor .path2:before {
  content: "\ec74";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-motion-sensor .path3:before {
  content: "\ec75";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-motion-sensor .path4:before {
  content: "\ec76";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-motion-sensor .path5:before {
  content: "\ec77";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-motion-sensor .path6:before {
  content: "\ec78";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-motion-sensor .path7:before {
  content: "\ec79";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-motion-sensor .path8:before {
  content: "\ec7a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-motion-sensor .path9:before {
  content: "\ec7b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-motion-sensor .path10:before {
  content: "\ec7c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-motion-sensor .path11:before {
  content: "\ec7d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-motion-sensor .path12:before {
  content: "\ec7e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path13:before {
  content: "\ec7f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path14:before {
  content: "\ec80";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path15:before {
  content: "\ec81";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path16:before {
  content: "\ec82";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-motion-sensor .path17:before {
  content: "\ec83";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mp4-player .path1:before {
  content: "\ec84";
  color: rgb(230, 231, 232);
}
.icon-mp4-player .path2:before {
  content: "\ec85";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mp4-player .path3:before {
  content: "\ec86";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mp4-player .path4:before {
  content: "\ec87";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mp4-player .path5:before {
  content: "\ec88";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-mp4-player .path6:before {
  content: "\ec89";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mp4-player .path7:before {
  content: "\ec8a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mp4-player .path8:before {
  content: "\ec8b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-mp4-player .path9:before {
  content: "\ec8c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mp4-player .path10:before {
  content: "\ec8d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-mp4-player .path11:before {
  content: "\ec8e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mp4-player .path12:before {
  content: "\ec8f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mp4-player .path13:before {
  content: "\ec90";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-mp4-player .path14:before {
  content: "\ec91";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mp4-player .path15:before {
  content: "\ec92";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mp4-player .path16:before {
  content: "\ec93";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-mp4-player .path17:before {
  content: "\ec94";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path1:before {
  content: "\ec95";
  color: rgb(230, 231, 232);
}
.icon-nfc-technology .path2:before {
  content: "\ec96";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-nfc-technology .path3:before {
  content: "\ec97";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-nfc-technology .path4:before {
  content: "\ec98";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-nfc-technology .path5:before {
  content: "\ec99";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-nfc-technology .path6:before {
  content: "\ec9a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-nfc-technology .path7:before {
  content: "\ec9b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-nfc-technology .path8:before {
  content: "\ec9c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-nfc-technology .path9:before {
  content: "\ec9d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-nfc-technology .path10:before {
  content: "\ec9e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-nfc-technology .path11:before {
  content: "\ec9f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-nfc-technology .path12:before {
  content: "\eca0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-nfc-technology .path13:before {
  content: "\eca1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-nfc-technology .path14:before {
  content: "\eca2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-nfc-technology .path15:before {
  content: "\eca3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path16:before {
  content: "\eca4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path17:before {
  content: "\eca5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path18:before {
  content: "\eca6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path19:before {
  content: "\eca7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-nfc-technology .path20:before {
  content: "\eca8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-on-demand-service .path1:before {
  content: "\eca9";
  color: rgb(230, 231, 232);
}
.icon-on-demand-service .path2:before {
  content: "\ecaa";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-on-demand-service .path3:before {
  content: "\ecab";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-on-demand-service .path4:before {
  content: "\ecac";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-on-demand-service .path5:before {
  content: "\ecad";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-on-demand-service .path6:before {
  content: "\ecae";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-on-demand-service .path7:before {
  content: "\ecaf";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-on-demand-service .path8:before {
  content: "\ecb0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-on-demand-service .path9:before {
  content: "\ecb1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-on-demand-service .path10:before {
  content: "\ecb2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-on-demand-service .path11:before {
  content: "\ecb3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-on-demand-service .path12:before {
  content: "\ecb4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-on-demand-service .path13:before {
  content: "\ecb5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-on-demand-service .path14:before {
  content: "\ecb6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-on-demand-service .path15:before {
  content: "\ecb7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path1:before {
  content: "\ecb8";
  color: rgb(230, 231, 232);
}
.icon-open-data .path2:before {
  content: "\ecb9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path3:before {
  content: "\ecba";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path4:before {
  content: "\ecbb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-open-data .path5:before {
  content: "\ecbc";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-open-data .path6:before {
  content: "\ecbd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path7:before {
  content: "\ecbe";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path8:before {
  content: "\ecbf";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path9:before {
  content: "\ecc0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-open-data .path10:before {
  content: "\ecc1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-open-data .path11:before {
  content: "\ecc2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-open-data .path12:before {
  content: "\ecc3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path13:before {
  content: "\ecc4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path14:before {
  content: "\ecc5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path15:before {
  content: "\ecc6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path16:before {
  content: "\ecc7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-open-data .path17:before {
  content: "\ecc8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path1:before {
  content: "\ecc9";
  color: rgb(230, 231, 232);
}
.icon-political-announcement .path2:before {
  content: "\ecca";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-political-announcement .path3:before {
  content: "\eccb";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-political-announcement .path4:before {
  content: "\eccc";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path5:before {
  content: "\eccd";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-political-announcement .path6:before {
  content: "\ecce";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path7:before {
  content: "\eccf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path8:before {
  content: "\ecd0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path9:before {
  content: "\ecd1";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-political-announcement .path10:before {
  content: "\ecd2";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-political-announcement .path11:before {
  content: "\ecd3";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-political-announcement .path12:before {
  content: "\ecd4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path13:before {
  content: "\ecd5";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-political-announcement .path14:before {
  content: "\ecd6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path15:before {
  content: "\ecd7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path16:before {
  content: "\ecd8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path17:before {
  content: "\ecd9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-political-announcement .path18:before {
  content: "\ecda";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-political-announcement .path19:before {
  content: "\ecdb";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-political-announcement .path20:before {
  content: "\ecdc";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-political-announcement .path21:before {
  content: "\ecdd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path22:before {
  content: "\ecde";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path23:before {
  content: "\ecdf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path24:before {
  content: "\ece0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path25:before {
  content: "\ece1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-political-announcement .path26:before {
  content: "\ece2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-prediction .path1:before {
  content: "\ece3";
  color: rgb(230, 231, 232);
}
.icon-prediction .path2:before {
  content: "\ece4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-prediction .path3:before {
  content: "\ece5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-prediction .path4:before {
  content: "\ece6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-prediction .path5:before {
  content: "\ece7";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-prediction .path6:before {
  content: "\ece8";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-prediction .path7:before {
  content: "\ece9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-prediction .path8:before {
  content: "\ecea";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-prediction .path9:before {
  content: "\eceb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-prediction .path10:before {
  content: "\ecec";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-prediction .path11:before {
  content: "\eced";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-prediction .path12:before {
  content: "\ecee";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-prediction .path13:before {
  content: "\ecef";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-prediction .path14:before {
  content: "\ecf0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-prediction .path15:before {
  content: "\ecf1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-prediction .path16:before {
  content: "\ecf2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-prediction .path17:before {
  content: "\ecf3";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-processing-power .path1:before {
  content: "\ecf4";
  color: rgb(230, 231, 232);
}
.icon-processing-power .path2:before {
  content: "\ecf5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-processing-power .path3:before {
  content: "\ecf6";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-processing-power .path4:before {
  content: "\ecf7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-processing-power .path5:before {
  content: "\ecf8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-processing-power .path6:before {
  content: "\ecf9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-processing-power .path7:before {
  content: "\ecfa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-processing-power .path8:before {
  content: "\ecfb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-processing-power .path9:before {
  content: "\ecfc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-public-safety .path1:before {
  content: "\ecfd";
  color: rgb(230, 231, 232);
}
.icon-public-safety .path2:before {
  content: "\ecfe";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-public-safety .path3:before {
  content: "\ecff";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-public-safety .path4:before {
  content: "\ed00";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-public-safety .path5:before {
  content: "\ed01";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path6:before {
  content: "\ed02";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path7:before {
  content: "\ed03";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path8:before {
  content: "\ed04";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path9:before {
  content: "\ed05";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path10:before {
  content: "\ed06";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path11:before {
  content: "\ed07";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path12:before {
  content: "\ed08";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path13:before {
  content: "\ed09";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path14:before {
  content: "\ed0a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path15:before {
  content: "\ed0b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-public-safety .path16:before {
  content: "\ed0c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path17:before {
  content: "\ed0d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path18:before {
  content: "\ed0e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path19:before {
  content: "\ed0f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path20:before {
  content: "\ed10";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path21:before {
  content: "\ed11";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path22:before {
  content: "\ed12";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path23:before {
  content: "\ed13";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path24:before {
  content: "\ed14";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path25:before {
  content: "\ed15";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path26:before {
  content: "\ed16";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path27:before {
  content: "\ed17";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path28:before {
  content: "\ed18";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path29:before {
  content: "\ed19";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path30:before {
  content: "\ed1a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-public-safety .path31:before {
  content: "\ed1b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-public-safety .path32:before {
  content: "\ed1c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-public-safety .path33:before {
  content: "\ed1d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-recycle .path1:before {
  content: "\ed1e";
  color: rgb(230, 231, 232);
}
.icon-recycle .path2:before {
  content: "\ed1f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-recycle .path3:before {
  content: "\ed20";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-recycle .path4:before {
  content: "\ed21";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-recycle .path5:before {
  content: "\ed22";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-recycle .path6:before {
  content: "\ed23";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-recycle .path7:before {
  content: "\ed24";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-recycle .path8:before {
  content: "\ed25";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-recycle .path9:before {
  content: "\ed26";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-recycle .path10:before {
  content: "\ed27";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-recycle .path11:before {
  content: "\ed28";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-recycle .path12:before {
  content: "\ed29";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-recycle .path13:before {
  content: "\ed2a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-recycle .path14:before {
  content: "\ed2b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-recycle .path15:before {
  content: "\ed2c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-recycle .path16:before {
  content: "\ed2d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-recycle .path17:before {
  content: "\ed2e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-recycle .path18:before {
  content: "\ed2f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-recycle .path19:before {
  content: "\ed30";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path1:before {
  content: "\ed31";
  color: rgb(230, 231, 232);
}
.icon-remote .path2:before {
  content: "\ed32";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote .path3:before {
  content: "\ed33";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote .path4:before {
  content: "\ed34";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote .path5:before {
  content: "\ed35";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote .path6:before {
  content: "\ed36";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path7:before {
  content: "\ed37";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote .path8:before {
  content: "\ed38";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-remote .path9:before {
  content: "\ed39";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path10:before {
  content: "\ed3a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path11:before {
  content: "\ed3b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote .path12:before {
  content: "\ed3c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path13:before {
  content: "\ed3d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path14:before {
  content: "\ed3e";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path15:before {
  content: "\ed3f";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path16:before {
  content: "\ed40";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path17:before {
  content: "\ed41";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path18:before {
  content: "\ed42";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path19:before {
  content: "\ed43";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path20:before {
  content: "\ed44";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path21:before {
  content: "\ed45";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote .path22:before {
  content: "\ed46";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote .path23:before {
  content: "\ed47";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path24:before {
  content: "\ed48";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote .path25:before {
  content: "\ed49";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path26:before {
  content: "\ed4a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote .path27:before {
  content: "\ed4b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote .path28:before {
  content: "\ed4c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote .path29:before {
  content: "\ed4d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path1:before {
  content: "\ed4e";
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path2:before {
  content: "\ed4f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path3:before {
  content: "\ed50";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path4:before {
  content: "\ed51";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path5:before {
  content: "\ed52";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path6:before {
  content: "\ed53";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path7:before {
  content: "\ed54";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path8:before {
  content: "\ed55";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path9:before {
  content: "\ed56";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path10:before {
  content: "\ed57";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-remote-vehicle .path11:before {
  content: "\ed58";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path12:before {
  content: "\ed59";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path13:before {
  content: "\ed5a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path14:before {
  content: "\ed5b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path15:before {
  content: "\ed5c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path16:before {
  content: "\ed5d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path17:before {
  content: "\ed5e";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path18:before {
  content: "\ed5f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path19:before {
  content: "\ed60";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path20:before {
  content: "\ed61";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path21:before {
  content: "\ed62";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path22:before {
  content: "\ed63";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path23:before {
  content: "\ed64";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-remote-vehicle .path24:before {
  content: "\ed65";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path25:before {
  content: "\ed66";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path26:before {
  content: "\ed67";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path27:before {
  content: "\ed68";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-remote-vehicle .path28:before {
  content: "\ed69";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path29:before {
  content: "\ed6a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path30:before {
  content: "\ed6b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-remote-vehicle .path31:before {
  content: "\ed6c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path32:before {
  content: "\ed6d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path33:before {
  content: "\ed6e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-remote-vehicle .path34:before {
  content: "\ed6f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path35:before {
  content: "\ed70";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path36:before {
  content: "\ed71";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path37:before {
  content: "\ed72";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path38:before {
  content: "\ed73";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-remote-vehicle .path39:before {
  content: "\ed74";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path40:before {
  content: "\ed75";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path41:before {
  content: "\ed76";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path42:before {
  content: "\ed77";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path43:before {
  content: "\ed78";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path44:before {
  content: "\ed79";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path45:before {
  content: "\ed7a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path46:before {
  content: "\ed7b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path47:before {
  content: "\ed7c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path48:before {
  content: "\ed7d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path49:before {
  content: "\ed7e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path50:before {
  content: "\ed7f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path51:before {
  content: "\ed80";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path52:before {
  content: "\ed81";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path53:before {
  content: "\ed82";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path54:before {
  content: "\ed83";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path55:before {
  content: "\ed84";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path56:before {
  content: "\ed85";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-remote-vehicle .path57:before {
  content: "\ed86";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-research .path1:before {
  content: "\ed87";
  color: rgb(230, 231, 232);
}
.icon-research .path2:before {
  content: "\ed88";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-research .path3:before {
  content: "\ed89";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-research .path4:before {
  content: "\ed8a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-research .path5:before {
  content: "\ed8b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-research .path6:before {
  content: "\ed8c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-research .path7:before {
  content: "\ed8d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-research .path8:before {
  content: "\ed8e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-research .path9:before {
  content: "\ed8f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-research .path10:before {
  content: "\ed90";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-research .path11:before {
  content: "\ed91";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-research .path12:before {
  content: "\ed92";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-research .path13:before {
  content: "\ed93";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path1:before {
  content: "\ed94";
  color: rgb(230, 231, 232);
}
.icon-robotics .path2:before {
  content: "\ed95";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robotics .path3:before {
  content: "\ed96";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robotics .path4:before {
  content: "\ed97";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robotics .path5:before {
  content: "\ed98";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robotics .path6:before {
  content: "\ed99";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robotics .path7:before {
  content: "\ed9a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-robotics .path8:before {
  content: "\ed9b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-robotics .path9:before {
  content: "\ed9c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robotics .path10:before {
  content: "\ed9d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-robotics .path11:before {
  content: "\ed9e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robotics .path12:before {
  content: "\ed9f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robotics .path13:before {
  content: "\eda0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-robotics .path14:before {
  content: "\eda1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-robotics .path15:before {
  content: "\eda2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-robotics .path16:before {
  content: "\eda3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path17:before {
  content: "\eda4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path18:before {
  content: "\eda5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path19:before {
  content: "\eda6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path20:before {
  content: "\eda7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path21:before {
  content: "\eda8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path22:before {
  content: "\eda9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robotics .path23:before {
  content: "\edaa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path1:before {
  content: "\edab";
  color: rgb(230, 231, 232);
}
.icon-robot-vacuum-cleaner .path2:before {
  content: "\edac";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robot-vacuum-cleaner .path3:before {
  content: "\edad";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robot-vacuum-cleaner .path4:before {
  content: "\edae";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robot-vacuum-cleaner .path5:before {
  content: "\edaf";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-robot-vacuum-cleaner .path6:before {
  content: "\edb0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robot-vacuum-cleaner .path7:before {
  content: "\edb1";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robot-vacuum-cleaner .path8:before {
  content: "\edb2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robot-vacuum-cleaner .path9:before {
  content: "\edb3";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-robot-vacuum-cleaner .path10:before {
  content: "\edb4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-robot-vacuum-cleaner .path11:before {
  content: "\edb5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-robot-vacuum-cleaner .path12:before {
  content: "\edb6";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-robot-vacuum-cleaner .path13:before {
  content: "\edb7";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-robot-vacuum-cleaner .path14:before {
  content: "\edb8";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-robot-vacuum-cleaner .path15:before {
  content: "\edb9";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-robot-vacuum-cleaner .path16:before {
  content: "\edba";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-robot-vacuum-cleaner .path17:before {
  content: "\edbb";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-robot-vacuum-cleaner .path18:before {
  content: "\edbc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path19:before {
  content: "\edbd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path20:before {
  content: "\edbe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path21:before {
  content: "\edbf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path22:before {
  content: "\edc0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path23:before {
  content: "\edc1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path24:before {
  content: "\edc2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path25:before {
  content: "\edc3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-robot-vacuum-cleaner .path26:before {
  content: "\edc4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-robot-vacuum-cleaner .path27:before {
  content: "\edc5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-satellite-technology .path1:before {
  content: "\edc6";
  color: rgb(230, 231, 232);
}
.icon-satellite-technology .path2:before {
  content: "\edc7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-satellite-technology .path3:before {
  content: "\edc8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-satellite-technology .path4:before {
  content: "\edc9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-satellite-technology .path5:before {
  content: "\edca";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-satellite-technology .path6:before {
  content: "\edcb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path7:before {
  content: "\edcc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path8:before {
  content: "\edcd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path9:before {
  content: "\edce";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path10:before {
  content: "\edcf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-satellite-technology .path11:before {
  content: "\edd0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-satellite-technology .path12:before {
  content: "\edd1";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-satellite-technology .path13:before {
  content: "\edd2";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-satellite-technology .path14:before {
  content: "\edd3";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path15:before {
  content: "\edd4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path16:before {
  content: "\edd5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path17:before {
  content: "\edd6";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-satellite-technology .path18:before {
  content: "\edd7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path19:before {
  content: "\edd8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path20:before {
  content: "\edd9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path21:before {
  content: "\edda";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path22:before {
  content: "\eddb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path23:before {
  content: "\eddc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path24:before {
  content: "\eddd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-satellite-technology .path25:before {
  content: "\edde";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path1:before {
  content: "\eddf";
  color: rgb(230, 231, 232);
}
.icon-security-panel .path2:before {
  content: "\ede0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-security-panel .path3:before {
  content: "\ede1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-security-panel .path4:before {
  content: "\ede2";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-security-panel .path5:before {
  content: "\ede3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-security-panel .path6:before {
  content: "\ede4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-security-panel .path7:before {
  content: "\ede5";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-security-panel .path8:before {
  content: "\ede6";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path9:before {
  content: "\ede7";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path10:before {
  content: "\ede8";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path11:before {
  content: "\ede9";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path12:before {
  content: "\edea";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path13:before {
  content: "\edeb";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path14:before {
  content: "\edec";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path15:before {
  content: "\eded";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path16:before {
  content: "\edee";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path17:before {
  content: "\edef";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-security-panel .path18:before {
  content: "\edf0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-security-panel .path19:before {
  content: "\edf1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path20:before {
  content: "\edf2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path21:before {
  content: "\edf3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path22:before {
  content: "\edf4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path23:before {
  content: "\edf5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path24:before {
  content: "\edf6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path25:before {
  content: "\edf7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path26:before {
  content: "\edf8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path27:before {
  content: "\edf9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path28:before {
  content: "\edfa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path29:before {
  content: "\edfb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path30:before {
  content: "\edfc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path31:before {
  content: "\edfd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path32:before {
  content: "\edfe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path33:before {
  content: "\edff";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path34:before {
  content: "\ee00";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path35:before {
  content: "\ee01";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path36:before {
  content: "\ee02";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path37:before {
  content: "\ee03";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path38:before {
  content: "\ee04";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-security-panel .path39:before {
  content: "\ee05";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-semantic-web .path1:before {
  content: "\ee06";
  color: rgb(230, 231, 232);
}
.icon-semantic-web .path2:before {
  content: "\ee07";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-semantic-web .path3:before {
  content: "\ee08";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-semantic-web .path4:before {
  content: "\ee09";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-semantic-web .path5:before {
  content: "\ee0a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-semantic-web .path6:before {
  content: "\ee0b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-semantic-web .path7:before {
  content: "\ee0c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-semantic-web .path8:before {
  content: "\ee0d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-semantic-web .path9:before {
  content: "\ee0e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-semantic-web .path10:before {
  content: "\ee0f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-semantic-web .path11:before {
  content: "\ee10";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-semantic-web .path12:before {
  content: "\ee11";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-buildings .path1:before {
  content: "\ee12";
  color: rgb(230, 231, 232);
}
.icon-smart-buildings .path2:before {
  content: "\ee13";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-buildings .path3:before {
  content: "\ee14";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-buildings .path4:before {
  content: "\ee15";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-buildings .path5:before {
  content: "\ee16";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-buildings .path6:before {
  content: "\ee17";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-buildings .path7:before {
  content: "\ee18";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path8:before {
  content: "\ee19";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path9:before {
  content: "\ee1a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path10:before {
  content: "\ee1b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path11:before {
  content: "\ee1c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path12:before {
  content: "\ee1d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path13:before {
  content: "\ee1e";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path14:before {
  content: "\ee1f";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path15:before {
  content: "\ee20";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-buildings .path16:before {
  content: "\ee21";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-buildings .path17:before {
  content: "\ee22";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path18:before {
  content: "\ee23";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path19:before {
  content: "\ee24";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path20:before {
  content: "\ee25";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path21:before {
  content: "\ee26";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path22:before {
  content: "\ee27";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-buildings .path23:before {
  content: "\ee28";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path24:before {
  content: "\ee29";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path25:before {
  content: "\ee2a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-buildings .path26:before {
  content: "\ee2b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-buildings .path27:before {
  content: "\ee2c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-buildings .path28:before {
  content: "\ee2d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-buildings .path29:before {
  content: "\ee2e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-buildings .path30:before {
  content: "\ee2f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-buildings .path31:before {
  content: "\ee30";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path1:before {
  content: "\ee31";
  color: rgb(230, 231, 232);
}
.icon-smart-care .path2:before {
  content: "\ee32";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-care .path3:before {
  content: "\ee33";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path4:before {
  content: "\ee34";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path5:before {
  content: "\ee35";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path6:before {
  content: "\ee36";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-care .path7:before {
  content: "\ee37";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-care .path8:before {
  content: "\ee38";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path9:before {
  content: "\ee39";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-care .path10:before {
  content: "\ee3a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path11:before {
  content: "\ee3b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path12:before {
  content: "\ee3c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path13:before {
  content: "\ee3d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path14:before {
  content: "\ee3e";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-care .path15:before {
  content: "\ee3f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path16:before {
  content: "\ee40";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path17:before {
  content: "\ee41";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path18:before {
  content: "\ee42";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-care .path19:before {
  content: "\ee43";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path1:before {
  content: "\ee44";
  color: rgb(230, 231, 232);
}
.icon-smart-connect .path2:before {
  content: "\ee45";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-connect .path3:before {
  content: "\ee46";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-connect .path4:before {
  content: "\ee47";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path5:before {
  content: "\ee48";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-connect .path6:before {
  content: "\ee49";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-connect .path7:before {
  content: "\ee4a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-connect .path8:before {
  content: "\ee4b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-connect .path9:before {
  content: "\ee4c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-connect .path10:before {
  content: "\ee4d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-connect .path11:before {
  content: "\ee4e";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-connect .path12:before {
  content: "\ee4f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path13:before {
  content: "\ee50";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-connect .path14:before {
  content: "\ee51";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-connect .path15:before {
  content: "\ee52";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-connect .path16:before {
  content: "\ee53";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path17:before {
  content: "\ee54";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path18:before {
  content: "\ee55";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path19:before {
  content: "\ee56";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-connect .path20:before {
  content: "\ee57";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path21:before {
  content: "\ee58";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path22:before {
  content: "\ee59";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path23:before {
  content: "\ee5a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path24:before {
  content: "\ee5b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path25:before {
  content: "\ee5c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path26:before {
  content: "\ee5d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-connect .path27:before {
  content: "\ee5e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-fridge .path1:before {
  content: "\ee5f";
  color: rgb(230, 231, 232);
}
.icon-smart-fridge .path2:before {
  content: "\ee60";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-fridge .path3:before {
  content: "\ee61";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-fridge .path4:before {
  content: "\ee62";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-fridge .path5:before {
  content: "\ee63";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-fridge .path6:before {
  content: "\ee64";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-fridge .path7:before {
  content: "\ee65";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-fridge .path8:before {
  content: "\ee66";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-fridge .path9:before {
  content: "\ee67";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-fridge .path10:before {
  content: "\ee68";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-fridge .path11:before {
  content: "\ee69";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-fridge .path12:before {
  content: "\ee6a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-fridge .path13:before {
  content: "\ee6b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-fridge .path14:before {
  content: "\ee6c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-fridge .path15:before {
  content: "\ee6d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-fridge .path16:before {
  content: "\ee6e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-fridge .path17:before {
  content: "\ee6f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-fridge .path18:before {
  content: "\ee70";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path1:before {
  content: "\ee71";
  color: rgb(230, 231, 232);
}
.icon-smart-glasses .path2:before {
  content: "\ee72";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path3:before {
  content: "\ee73";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-glasses .path4:before {
  content: "\ee74";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path5:before {
  content: "\ee75";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-glasses .path6:before {
  content: "\ee76";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-glasses .path7:before {
  content: "\ee77";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-glasses .path8:before {
  content: "\ee78";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path9:before {
  content: "\ee79";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path10:before {
  content: "\ee7a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path11:before {
  content: "\ee7b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-glasses .path12:before {
  content: "\ee7c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path13:before {
  content: "\ee7d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path14:before {
  content: "\ee7e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-glasses .path15:before {
  content: "\ee7f";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-glasses .path16:before {
  content: "\ee80";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-glasses .path17:before {
  content: "\ee81";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-glasses .path18:before {
  content: "\ee82";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-glasses .path19:before {
  content: "\ee83";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-glasses .path20:before {
  content: "\ee84";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-glasses .path21:before {
  content: "\ee85";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-highway .path1:before {
  content: "\ee86";
  color: rgb(230, 231, 232);
}
.icon-smart-highway .path2:before {
  content: "\ee87";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-highway .path3:before {
  content: "\ee88";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-highway .path4:before {
  content: "\ee89";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-highway .path5:before {
  content: "\ee8a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-highway .path6:before {
  content: "\ee8b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-highway .path7:before {
  content: "\ee8c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-highway .path8:before {
  content: "\ee8d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-highway .path9:before {
  content: "\ee8e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-highway .path10:before {
  content: "\ee8f";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-highway .path11:before {
  content: "\ee90";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-highway .path12:before {
  content: "\ee91";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-highway .path13:before {
  content: "\ee92";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-highway .path14:before {
  content: "\ee93";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-highway .path15:before {
  content: "\ee94";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-highway .path16:before {
  content: "\ee95";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path1:before {
  content: "\ee96";
  color: rgb(230, 231, 232);
}
.icon-smart-home .path2:before {
  content: "\ee97";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-home .path3:before {
  content: "\ee98";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-home .path4:before {
  content: "\ee99";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-home .path5:before {
  content: "\ee9a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-home .path6:before {
  content: "\ee9b";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-home .path7:before {
  content: "\ee9c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path8:before {
  content: "\ee9d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-home .path9:before {
  content: "\ee9e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path10:before {
  content: "\ee9f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path11:before {
  content: "\eea0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path12:before {
  content: "\eea1";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path13:before {
  content: "\eea2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path14:before {
  content: "\eea3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path15:before {
  content: "\eea4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path16:before {
  content: "\eea5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-home .path17:before {
  content: "\eea6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path18:before {
  content: "\eea7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path19:before {
  content: "\eea8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path20:before {
  content: "\eea9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path21:before {
  content: "\eeaa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path22:before {
  content: "\eeab";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path23:before {
  content: "\eeac";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path24:before {
  content: "\eead";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path25:before {
  content: "\eeae";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path26:before {
  content: "\eeaf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path27:before {
  content: "\eeb0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path28:before {
  content: "\eeb1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path29:before {
  content: "\eeb2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-home .path30:before {
  content: "\eeb3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path1:before {
  content: "\eeb4";
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path2:before {
  content: "\eeb5";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path3:before {
  content: "\eeb6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path4:before {
  content: "\eeb7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path5:before {
  content: "\eeb8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path6:before {
  content: "\eeb9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path7:before {
  content: "\eeba";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-lock .path8:before {
  content: "\eebb";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-lock .path9:before {
  content: "\eebc";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-lock .path10:before {
  content: "\eebd";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-lock .path11:before {
  content: "\eebe";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-lock .path12:before {
  content: "\eebf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-lock .path13:before {
  content: "\eec0";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-lock .path14:before {
  content: "\eec1";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path15:before {
  content: "\eec2";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path16:before {
  content: "\eec3";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path17:before {
  content: "\eec4";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path18:before {
  content: "\eec5";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path19:before {
  content: "\eec6";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-lock .path20:before {
  content: "\eec7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path21:before {
  content: "\eec8";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path22:before {
  content: "\eec9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path23:before {
  content: "\eeca";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path24:before {
  content: "\eecb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path25:before {
  content: "\eecc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path26:before {
  content: "\eecd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-lock .path27:before {
  content: "\eece";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path28:before {
  content: "\eecf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path29:before {
  content: "\eed0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path30:before {
  content: "\eed1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path31:before {
  content: "\eed2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path32:before {
  content: "\eed3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path33:before {
  content: "\eed4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path34:before {
  content: "\eed5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path35:before {
  content: "\eed6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path36:before {
  content: "\eed7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path37:before {
  content: "\eed8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path38:before {
  content: "\eed9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path39:before {
  content: "\eeda";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path40:before {
  content: "\eedb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path41:before {
  content: "\eedc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-lock .path42:before {
  content: "\eedd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path1:before {
  content: "\eede";
  color: rgb(230, 231, 232);
}
.icon-smart-meter .path2:before {
  content: "\eedf";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-meter .path3:before {
  content: "\eee0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-meter .path4:before {
  content: "\eee1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-meter .path5:before {
  content: "\eee2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-meter .path6:before {
  content: "\eee3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-meter .path7:before {
  content: "\eee4";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-meter .path8:before {
  content: "\eee5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-meter .path9:before {
  content: "\eee6";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-meter .path10:before {
  content: "\eee7";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-meter .path11:before {
  content: "\eee8";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-meter .path12:before {
  content: "\eee9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path13:before {
  content: "\eeea";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path14:before {
  content: "\eeeb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path15:before {
  content: "\eeec";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path16:before {
  content: "\eeed";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-meter .path17:before {
  content: "\eeee";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-meter .path18:before {
  content: "\eeef";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-meter .path19:before {
  content: "\eef0";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-meter .path20:before {
  content: "\eef1";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-microwave .path1:before {
  content: "\eef2";
  color: rgb(230, 231, 232);
}
.icon-smart-microwave .path2:before {
  content: "\eef3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-microwave .path3:before {
  content: "\eef4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-microwave .path4:before {
  content: "\eef5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-microwave .path5:before {
  content: "\eef6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-microwave .path6:before {
  content: "\eef7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-microwave .path7:before {
  content: "\eef8";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-microwave .path8:before {
  content: "\eef9";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-microwave .path9:before {
  content: "\eefa";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-microwave .path10:before {
  content: "\eefb";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-microwave .path11:before {
  content: "\eefc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-microwave .path12:before {
  content: "\eefd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path13:before {
  content: "\eefe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path14:before {
  content: "\eeff";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path15:before {
  content: "\ef00";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path16:before {
  content: "\ef01";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path17:before {
  content: "\ef02";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path18:before {
  content: "\ef03";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-microwave .path19:before {
  content: "\ef04";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-microwave .path20:before {
  content: "\ef05";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-microwave .path21:before {
  content: "\ef06";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-microwave .path22:before {
  content: "\ef07";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smartphone .path1:before {
  content: "\ef08";
  color: rgb(230, 231, 232);
}
.icon-smartphone .path2:before {
  content: "\ef09";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path3:before {
  content: "\ef0a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smartphone .path4:before {
  content: "\ef0b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smartphone .path5:before {
  content: "\ef0c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smartphone .path6:before {
  content: "\ef0d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smartphone .path7:before {
  content: "\ef0e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smartphone .path8:before {
  content: "\ef0f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smartphone .path9:before {
  content: "\ef10";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path10:before {
  content: "\ef11";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path11:before {
  content: "\ef12";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path12:before {
  content: "\ef13";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path13:before {
  content: "\ef14";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smartphone .path14:before {
  content: "\ef15";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path15:before {
  content: "\ef16";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path16:before {
  content: "\ef17";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path17:before {
  content: "\ef18";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path18:before {
  content: "\ef19";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path19:before {
  content: "\ef1a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path20:before {
  content: "\ef1b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path21:before {
  content: "\ef1c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path22:before {
  content: "\ef1d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path23:before {
  content: "\ef1e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smartphone .path24:before {
  content: "\ef1f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-product-management .path1:before {
  content: "\ef20";
  color: rgb(230, 231, 232);
}
.icon-smart-product-management .path2:before {
  content: "\ef21";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-product-management .path3:before {
  content: "\ef22";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-product-management .path4:before {
  content: "\ef23";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-product-management .path5:before {
  content: "\ef24";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-product-management .path6:before {
  content: "\ef25";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-product-management .path7:before {
  content: "\ef26";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-product-management .path8:before {
  content: "\ef27";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-product-management .path9:before {
  content: "\ef28";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-product-management .path10:before {
  content: "\ef29";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-product-management .path11:before {
  content: "\ef2a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-product-management .path12:before {
  content: "\ef2b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-product-management .path13:before {
  content: "\ef2c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-product-management .path14:before {
  content: "\ef2d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-product-management .path15:before {
  content: "\ef2e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-product-management .path16:before {
  content: "\ef2f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-product-management .path17:before {
  content: "\ef30";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-retail .path1:before {
  content: "\ef31";
  color: rgb(230, 231, 232);
}
.icon-smart-retail .path2:before {
  content: "\ef32";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-retail .path3:before {
  content: "\ef33";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-retail .path4:before {
  content: "\ef34";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-retail .path5:before {
  content: "\ef35";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-retail .path6:before {
  content: "\ef36";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-retail .path7:before {
  content: "\ef37";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-retail .path8:before {
  content: "\ef38";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-retail .path9:before {
  content: "\ef39";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-retail .path10:before {
  content: "\ef3a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-retail .path11:before {
  content: "\ef3b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-retail .path12:before {
  content: "\ef3c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-retail .path13:before {
  content: "\ef3d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-retail .path14:before {
  content: "\ef3e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-retail .path15:before {
  content: "\ef3f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-retail .path16:before {
  content: "\ef40";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-sim .path1:before {
  content: "\ef41";
  color: rgb(230, 231, 232);
}
.icon-smart-sim .path2:before {
  content: "\ef42";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-sim .path3:before {
  content: "\ef43";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-sim .path4:before {
  content: "\ef44";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-sim .path5:before {
  content: "\ef45";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-sim .path6:before {
  content: "\ef46";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-sim .path7:before {
  content: "\ef47";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-sim .path8:before {
  content: "\ef48";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-sim .path9:before {
  content: "\ef49";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-sim .path10:before {
  content: "\ef4a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-sim .path11:before {
  content: "\ef4b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-sim .path12:before {
  content: "\ef4c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path1:before {
  content: "\ef4d";
  color: rgb(230, 231, 232);
}
.icon-smart-thermostat .path2:before {
  content: "\ef4e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-thermostat .path3:before {
  content: "\ef4f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-thermostat .path4:before {
  content: "\ef50";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-thermostat .path5:before {
  content: "\ef51";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-thermostat .path6:before {
  content: "\ef52";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-thermostat .path7:before {
  content: "\ef53";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-thermostat .path8:before {
  content: "\ef54";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-thermostat .path9:before {
  content: "\ef55";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-thermostat .path10:before {
  content: "\ef56";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-thermostat .path11:before {
  content: "\ef57";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-thermostat .path12:before {
  content: "\ef58";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-thermostat .path13:before {
  content: "\ef59";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-thermostat .path14:before {
  content: "\ef5a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-thermostat .path15:before {
  content: "\ef5b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-thermostat .path16:before {
  content: "\ef5c";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-thermostat .path17:before {
  content: "\ef5d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-thermostat .path18:before {
  content: "\ef5e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path19:before {
  content: "\ef5f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path20:before {
  content: "\ef60";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path21:before {
  content: "\ef61";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path22:before {
  content: "\ef62";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path23:before {
  content: "\ef63";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path24:before {
  content: "\ef64";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path25:before {
  content: "\ef65";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-thermostat .path26:before {
  content: "\ef66";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-timer .path1:before {
  content: "\ef67";
  color: rgb(230, 231, 232);
}
.icon-smart-timer .path2:before {
  content: "\ef68";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-timer .path3:before {
  content: "\ef69";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-timer .path4:before {
  content: "\ef6a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-timer .path5:before {
  content: "\ef6b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-timer .path6:before {
  content: "\ef6c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-timer .path7:before {
  content: "\ef6d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-timer .path8:before {
  content: "\ef6e";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-timer .path9:before {
  content: "\ef6f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-timer .path10:before {
  content: "\ef70";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-timer .path11:before {
  content: "\ef71";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-timer .path12:before {
  content: "\ef72";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-timer .path13:before {
  content: "\ef73";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-timer .path14:before {
  content: "\ef74";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-timer .path15:before {
  content: "\ef75";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-timer .path16:before {
  content: "\ef76";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-timer .path17:before {
  content: "\ef77";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv .path1:before {
  content: "\ef78";
  color: rgb(230, 231, 232);
}
.icon-smart-tv .path2:before {
  content: "\ef79";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv .path3:before {
  content: "\ef7a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-tv .path4:before {
  content: "\ef7b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path5:before {
  content: "\ef7c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv .path6:before {
  content: "\ef7d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-tv .path7:before {
  content: "\ef7e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv .path8:before {
  content: "\ef7f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-tv .path9:before {
  content: "\ef80";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path10:before {
  content: "\ef81";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path11:before {
  content: "\ef82";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-tv .path12:before {
  content: "\ef83";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv .path13:before {
  content: "\ef84";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path14:before {
  content: "\ef85";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path15:before {
  content: "\ef86";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-tv .path16:before {
  content: "\ef87";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv .path17:before {
  content: "\ef88";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv .path18:before {
  content: "\ef89";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv .path19:before {
  content: "\ef8a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv .path20:before {
  content: "\ef8b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path1:before {
  content: "\ef8c";
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path2:before {
  content: "\ef8d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path3:before {
  content: "\ef8e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path4:before {
  content: "\ef8f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path5:before {
  content: "\ef90";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path6:before {
  content: "\ef91";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-tv-box .path7:before {
  content: "\ef92";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-tv-box .path8:before {
  content: "\ef93";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv-box .path9:before {
  content: "\ef94";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv-box .path10:before {
  content: "\ef95";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path11:before {
  content: "\ef96";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv-box .path12:before {
  content: "\ef97";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path13:before {
  content: "\ef98";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv-box .path14:before {
  content: "\ef99";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path15:before {
  content: "\ef9a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path16:before {
  content: "\ef9b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path17:before {
  content: "\ef9c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-tv-box .path18:before {
  content: "\ef9d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path19:before {
  content: "\ef9e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path20:before {
  content: "\ef9f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv-box .path21:before {
  content: "\efa0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path22:before {
  content: "\efa1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-tv-box .path23:before {
  content: "\efa2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path24:before {
  content: "\efa3";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path25:before {
  content: "\efa4";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path26:before {
  content: "\efa5";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path27:before {
  content: "\efa6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path28:before {
  content: "\efa7";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-tv-box .path29:before {
  content: "\efa8";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-tv-box .path30:before {
  content: "\efa9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path31:before {
  content: "\efaa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path32:before {
  content: "\efab";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-tv-box .path33:before {
  content: "\efac";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path1:before {
  content: "\efad";
  color: rgb(230, 231, 232);
}
.icon-smart-washing-machine .path2:before {
  content: "\efae";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-washing-machine .path3:before {
  content: "\efaf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-washing-machine .path4:before {
  content: "\efb0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-washing-machine .path5:before {
  content: "\efb1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-washing-machine .path6:before {
  content: "\efb2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-washing-machine .path7:before {
  content: "\efb3";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-washing-machine .path8:before {
  content: "\efb4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-washing-machine .path9:before {
  content: "\efb5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-washing-machine .path10:before {
  content: "\efb6";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-washing-machine .path11:before {
  content: "\efb7";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-washing-machine .path12:before {
  content: "\efb8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path13:before {
  content: "\efb9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path14:before {
  content: "\efba";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path15:before {
  content: "\efbb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path16:before {
  content: "\efbc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path17:before {
  content: "\efbd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path18:before {
  content: "\efbe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-washing-machine .path19:before {
  content: "\efbf";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-washing-machine .path20:before {
  content: "\efc0";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-washing-machine .path21:before {
  content: "\efc1";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-washing-machine .path22:before {
  content: "\efc2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path1:before {
  content: "\efc3";
  color: rgb(230, 231, 232);
}
.icon-smart-watch .path2:before {
  content: "\efc4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path3:before {
  content: "\efc5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-watch .path4:before {
  content: "\efc6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-watch .path5:before {
  content: "\efc7";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path6:before {
  content: "\efc8";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path7:before {
  content: "\efc9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-watch .path8:before {
  content: "\efca";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-watch .path9:before {
  content: "\efcb";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path10:before {
  content: "\efcc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-watch .path11:before {
  content: "\efcd";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-watch .path12:before {
  content: "\efce";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-watch .path13:before {
  content: "\efcf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path14:before {
  content: "\efd0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-watch .path15:before {
  content: "\efd1";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-watch .path16:before {
  content: "\efd2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-watch .path17:before {
  content: "\efd3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-watch .path18:before {
  content: "\efd4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-watch .path19:before {
  content: "\efd5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-watch .path20:before {
  content: "\efd6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-watch .path21:before {
  content: "\efd7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-water .path1:before {
  content: "\efd8";
  color: rgb(230, 231, 232);
}
.icon-smart-water .path2:before {
  content: "\efd9";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smart-water .path3:before {
  content: "\efda";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-water .path4:before {
  content: "\efdb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-water .path5:before {
  content: "\efdc";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-water .path6:before {
  content: "\efdd";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-water .path7:before {
  content: "\efde";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-water .path8:before {
  content: "\efdf";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-water .path9:before {
  content: "\efe0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-water .path10:before {
  content: "\efe1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-water .path11:before {
  content: "\efe2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-water .path12:before {
  content: "\efe3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-water .path13:before {
  content: "\efe4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path1:before {
  content: "\efe5";
  color: rgb(230, 231, 232);
}
.icon-smart-working .path2:before {
  content: "\efe6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smart-working .path3:before {
  content: "\efe7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-working .path4:before {
  content: "\efe8";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-smart-working .path5:before {
  content: "\efe9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-working .path6:before {
  content: "\efea";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-working .path7:before {
  content: "\efeb";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-working .path8:before {
  content: "\efec";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-smart-working .path9:before {
  content: "\efed";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smart-working .path10:before {
  content: "\efee";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smart-working .path11:before {
  content: "\efef";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smart-working .path12:before {
  content: "\eff0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path13:before {
  content: "\eff1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path14:before {
  content: "\eff2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path15:before {
  content: "\eff3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path16:before {
  content: "\eff4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path17:before {
  content: "\eff5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path18:before {
  content: "\eff6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path19:before {
  content: "\eff7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path20:before {
  content: "\eff8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path21:before {
  content: "\eff9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path22:before {
  content: "\effa";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path23:before {
  content: "\effb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path24:before {
  content: "\effc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path25:before {
  content: "\effd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path26:before {
  content: "\effe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smart-working .path27:before {
  content: "\efff";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path1:before {
  content: "\f000";
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path2:before {
  content: "\f001";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path3:before {
  content: "\f002";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path4:before {
  content: "\f003";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path5:before {
  content: "\f004";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path6:before {
  content: "\f005";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path7:before {
  content: "\f006";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path8:before {
  content: "\f007";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path9:before {
  content: "\f008";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-smoke-sensor .path10:before {
  content: "\f009";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-smoke-sensor .path11:before {
  content: "\f00a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path12:before {
  content: "\f00b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path13:before {
  content: "\f00c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path14:before {
  content: "\f00d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path15:before {
  content: "\f00e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path16:before {
  content: "\f00f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path17:before {
  content: "\f010";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path18:before {
  content: "\f011";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path19:before {
  content: "\f012";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path20:before {
  content: "\f013";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path21:before {
  content: "\f014";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path22:before {
  content: "\f015";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-smoke-sensor .path23:before {
  content: "\f016";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path24:before {
  content: "\f017";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path25:before {
  content: "\f018";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path26:before {
  content: "\f019";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path27:before {
  content: "\f01a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-smoke-sensor .path28:before {
  content: "\f01b";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-smoke-sensor .path29:before {
  content: "\f01c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path30:before {
  content: "\f01d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path31:before {
  content: "\f01e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-smoke-sensor .path32:before {
  content: "\f01f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path1:before {
  content: "\f020";
  color: rgb(230, 231, 232);
}
.icon-social-media .path2:before {
  content: "\f021";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-social-media .path3:before {
  content: "\f022";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-social-media .path4:before {
  content: "\f023";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-social-media .path5:before {
  content: "\f024";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-social-media .path6:before {
  content: "\f025";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-social-media .path7:before {
  content: "\f026";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-social-media .path8:before {
  content: "\f027";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-social-media .path9:before {
  content: "\f028";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-social-media .path10:before {
  content: "\f029";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-social-media .path11:before {
  content: "\f02a";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-social-media .path12:before {
  content: "\f02b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-social-media .path13:before {
  content: "\f02c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-social-media .path14:before {
  content: "\f02d";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-social-media .path15:before {
  content: "\f02e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-social-media .path16:before {
  content: "\f02f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path17:before {
  content: "\f030";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path18:before {
  content: "\f031";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path19:before {
  content: "\f032";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path20:before {
  content: "\f033";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path21:before {
  content: "\f034";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-social-media .path22:before {
  content: "\f035";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path1:before {
  content: "\f036";
  color: rgb(230, 231, 232);
}
.icon-solar-energy .path2:before {
  content: "\f037";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-solar-energy .path3:before {
  content: "\f038";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-solar-energy .path4:before {
  content: "\f039";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-solar-energy .path5:before {
  content: "\f03a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-solar-energy .path6:before {
  content: "\f03b";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-solar-energy .path7:before {
  content: "\f03c";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-solar-energy .path8:before {
  content: "\f03d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-solar-energy .path9:before {
  content: "\f03e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path10:before {
  content: "\f03f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path11:before {
  content: "\f040";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path12:before {
  content: "\f041";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path13:before {
  content: "\f042";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path14:before {
  content: "\f043";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path15:before {
  content: "\f044";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path16:before {
  content: "\f045";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path17:before {
  content: "\f046";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path18:before {
  content: "\f047";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-solar-energy .path19:before {
  content: "\f048";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-tablet .path1:before {
  content: "\f049";
  color: rgb(230, 231, 232);
}
.icon-tablet .path2:before {
  content: "\f04a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-tablet .path3:before {
  content: "\f04b";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-tablet .path4:before {
  content: "\f04c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-tablet .path5:before {
  content: "\f04d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-tablet .path6:before {
  content: "\f04e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-tablet .path7:before {
  content: "\f04f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-tablet .path8:before {
  content: "\f050";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-tablet .path9:before {
  content: "\f051";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-tablet .path10:before {
  content: "\f052";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-tablet .path11:before {
  content: "\f053";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path1:before {
  content: "\f054";
  color: rgb(230, 231, 232);
}
.icon-traffic-control .path2:before {
  content: "\f055";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-traffic-control .path3:before {
  content: "\f056";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-traffic-control .path4:before {
  content: "\f057";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-traffic-control .path5:before {
  content: "\f058";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-traffic-control .path6:before {
  content: "\f059";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-traffic-control .path7:before {
  content: "\f05a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-traffic-control .path8:before {
  content: "\f05b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-traffic-control .path9:before {
  content: "\f05c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-traffic-control .path10:before {
  content: "\f05d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-traffic-control .path11:before {
  content: "\f05e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path12:before {
  content: "\f05f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path13:before {
  content: "\f060";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path14:before {
  content: "\f061";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path15:before {
  content: "\f062";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path16:before {
  content: "\f063";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path17:before {
  content: "\f064";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-traffic-control .path18:before {
  content: "\f065";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-traffic-control .path19:before {
  content: "\f066";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-traffic-control .path20:before {
  content: "\f067";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-traffic-control .path21:before {
  content: "\f068";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-traffic-control .path22:before {
  content: "\f069";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path23:before {
  content: "\f06a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path24:before {
  content: "\f06b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path25:before {
  content: "\f06c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path26:before {
  content: "\f06d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path27:before {
  content: "\f06e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path28:before {
  content: "\f06f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-traffic-control .path29:before {
  content: "\f070";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path1:before {
  content: "\f071";
  color: rgb(230, 231, 232);
}
.icon-train .path2:before {
  content: "\f072";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-train .path3:before {
  content: "\f073";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-train .path4:before {
  content: "\f074";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-train .path5:before {
  content: "\f075";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-train .path6:before {
  content: "\f076";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-train .path7:before {
  content: "\f077";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-train .path8:before {
  content: "\f078";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-train .path9:before {
  content: "\f079";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-train .path10:before {
  content: "\f07a";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-train .path11:before {
  content: "\f07b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-train .path12:before {
  content: "\f07c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-train .path13:before {
  content: "\f07d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-train .path14:before {
  content: "\f07e";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-train .path15:before {
  content: "\f07f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-train .path16:before {
  content: "\f080";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-train .path17:before {
  content: "\f081";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path18:before {
  content: "\f082";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path19:before {
  content: "\f083";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path20:before {
  content: "\f084";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path21:before {
  content: "\f085";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-train .path22:before {
  content: "\f086";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-uav .path1:before {
  content: "\f087";
  color: rgb(230, 231, 232);
}
.icon-uav .path2:before {
  content: "\f088";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path3:before {
  content: "\f089";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path4:before {
  content: "\f08a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-uav .path5:before {
  content: "\f08b";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path6:before {
  content: "\f08c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path7:before {
  content: "\f08d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-uav .path8:before {
  content: "\f08e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path9:before {
  content: "\f08f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path10:before {
  content: "\f090";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-uav .path11:before {
  content: "\f091";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path12:before {
  content: "\f092";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-uav .path13:before {
  content: "\f093";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-uav .path14:before {
  content: "\f094";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-uav .path15:before {
  content: "\f095";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-uav .path16:before {
  content: "\f096";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-uav .path17:before {
  content: "\f097";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-uav .path18:before {
  content: "\f098";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-uav .path19:before {
  content: "\f099";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-urban-agriculture .path1:before {
  content: "\f09a";
  color: rgb(230, 231, 232);
}
.icon-urban-agriculture .path2:before {
  content: "\f09b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-urban-agriculture .path3:before {
  content: "\f09c";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path4:before {
  content: "\f09d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path5:before {
  content: "\f09e";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-urban-agriculture .path6:before {
  content: "\f09f";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path7:before {
  content: "\f0a0";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path8:before {
  content: "\f0a1";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path9:before {
  content: "\f0a2";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path10:before {
  content: "\f0a3";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path11:before {
  content: "\f0a4";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path12:before {
  content: "\f0a5";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path13:before {
  content: "\f0a6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path14:before {
  content: "\f0a7";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path15:before {
  content: "\f0a8";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path16:before {
  content: "\f0a9";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-urban-agriculture .path17:before {
  content: "\f0aa";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-urban-agriculture .path18:before {
  content: "\f0ab";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path19:before {
  content: "\f0ac";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path20:before {
  content: "\f0ad";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path21:before {
  content: "\f0ae";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path22:before {
  content: "\f0af";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-urban-agriculture .path23:before {
  content: "\f0b0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-urban-agriculture .path24:before {
  content: "\f0b1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path1:before {
  content: "\f0b2";
  color: rgb(230, 231, 232);
}
.icon-user-driven-innovation .path2:before {
  content: "\f0b3";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-user-driven-innovation .path3:before {
  content: "\f0b4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-user-driven-innovation .path4:before {
  content: "\f0b5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-user-driven-innovation .path5:before {
  content: "\f0b6";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-user-driven-innovation .path6:before {
  content: "\f0b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-user-driven-innovation .path7:before {
  content: "\f0b8";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-user-driven-innovation .path8:before {
  content: "\f0b9";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-user-driven-innovation .path9:before {
  content: "\f0ba";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-user-driven-innovation .path10:before {
  content: "\f0bb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-user-driven-innovation .path11:before {
  content: "\f0bc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-user-driven-innovation .path12:before {
  content: "\f0bd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-user-driven-innovation .path13:before {
  content: "\f0be";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path14:before {
  content: "\f0bf";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path15:before {
  content: "\f0c0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path16:before {
  content: "\f0c1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path17:before {
  content: "\f0c2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path18:before {
  content: "\f0c3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-user-driven-innovation .path19:before {
  content: "\f0c4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path1:before {
  content: "\f0c5";
  color: rgb(230, 231, 232);
}
.icon-vehicle-emission-control .path2:before {
  content: "\f0c6";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vehicle-emission-control .path3:before {
  content: "\f0c7";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vehicle-emission-control .path4:before {
  content: "\f0c8";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vehicle-emission-control .path5:before {
  content: "\f0c9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vehicle-emission-control .path6:before {
  content: "\f0ca";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vehicle-emission-control .path7:before {
  content: "\f0cb";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vehicle-emission-control .path8:before {
  content: "\f0cc";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vehicle-emission-control .path9:before {
  content: "\f0cd";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path10:before {
  content: "\f0ce";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vehicle-emission-control .path11:before {
  content: "\f0cf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vehicle-emission-control .path12:before {
  content: "\f0d0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-vehicle-emission-control .path13:before {
  content: "\f0d1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path14:before {
  content: "\f0d2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path15:before {
  content: "\f0d3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vehicle-emission-control .path16:before {
  content: "\f0d4";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-vehicle-emission-control .path17:before {
  content: "\f0d5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path18:before {
  content: "\f0d6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path19:before {
  content: "\f0d7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vehicle-emission-control .path20:before {
  content: "\f0d8";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vehicle-emission-control .path21:before {
  content: "\f0d9";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vehicle-emission-control .path22:before {
  content: "\f0da";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path23:before {
  content: "\f0db";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path24:before {
  content: "\f0dc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path25:before {
  content: "\f0dd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path26:before {
  content: "\f0de";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path27:before {
  content: "\f0df";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path28:before {
  content: "\f0e0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path29:before {
  content: "\f0e1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path30:before {
  content: "\f0e2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vehicle-emission-control .path31:before {
  content: "\f0e3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vigilante .path1:before {
  content: "\f0e4";
  color: rgb(230, 231, 232);
}
.icon-vigilante .path2:before {
  content: "\f0e5";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path3:before {
  content: "\f0e6";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path4:before {
  content: "\f0e7";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vigilante .path5:before {
  content: "\f0e8";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-vigilante .path6:before {
  content: "\f0e9";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path7:before {
  content: "\f0ea";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path8:before {
  content: "\f0eb";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path9:before {
  content: "\f0ec";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vigilante .path10:before {
  content: "\f0ed";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vigilante .path11:before {
  content: "\f0ee";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path12:before {
  content: "\f0ef";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-vigilante .path13:before {
  content: "\f0f0";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-vigilante .path14:before {
  content: "\f0f1";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path15:before {
  content: "\f0f2";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vigilante .path16:before {
  content: "\f0f3";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path17:before {
  content: "\f0f4";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vigilante .path18:before {
  content: "\f0f5";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vigilante .path19:before {
  content: "\f0f6";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vigilante .path20:before {
  content: "\f0f7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path21:before {
  content: "\f0f8";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path22:before {
  content: "\f0f9";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vigilante .path23:before {
  content: "\f0fa";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vigilante .path24:before {
  content: "\f0fb";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vigilante .path25:before {
  content: "\f0fc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vigilante .path26:before {
  content: "\f0fd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vigilante .path27:before {
  content: "\f0fe";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-virtual-circuit .path1:before {
  content: "\f0ff";
  color: rgb(230, 231, 232);
}
.icon-virtual-circuit .path2:before {
  content: "\f100";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-virtual-circuit .path3:before {
  content: "\f101";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-virtual-circuit .path4:before {
  content: "\f102";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-virtual-circuit .path5:before {
  content: "\f103";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-virtual-circuit .path6:before {
  content: "\f104";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-virtual-circuit .path7:before {
  content: "\f105";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-virtual-circuit .path8:before {
  content: "\f106";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-virtual-circuit .path9:before {
  content: "\f107";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-virtual-circuit .path10:before {
  content: "\f108";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-virtual-circuit .path11:before {
  content: "\f109";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-virtual-circuit .path12:before {
  content: "\f10a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-virtual-circuit .path13:before {
  content: "\f10b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vr-technology .path1:before {
  content: "\f10c";
  color: rgb(230, 231, 232);
}
.icon-vr-technology .path2:before {
  content: "\f10d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vr-technology .path3:before {
  content: "\f10e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vr-technology .path4:before {
  content: "\f10f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-vr-technology .path5:before {
  content: "\f110";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-vr-technology .path6:before {
  content: "\f111";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vr-technology .path7:before {
  content: "\f112";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vr-technology .path8:before {
  content: "\f113";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vr-technology .path9:before {
  content: "\f114";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-vr-technology .path10:before {
  content: "\f115";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vr-technology .path11:before {
  content: "\f116";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vr-technology .path12:before {
  content: "\f117";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-vr-technology .path13:before {
  content: "\f118";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-vr-technology .path14:before {
  content: "\f119";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vr-technology .path15:before {
  content: "\f11a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vr-technology .path16:before {
  content: "\f11b";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-vr-technology .path17:before {
  content: "\f11c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vr-technology .path18:before {
  content: "\f11d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vr-technology .path19:before {
  content: "\f11e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vr-technology .path20:before {
  content: "\f11f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-vr-technology .path21:before {
  content: "\f120";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wearable-technology .path1:before {
  content: "\f121";
  color: rgb(230, 231, 232);
}
.icon-wearable-technology .path2:before {
  content: "\f122";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wearable-technology .path3:before {
  content: "\f123";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wearable-technology .path4:before {
  content: "\f124";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path5:before {
  content: "\f125";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wearable-technology .path6:before {
  content: "\f126";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wearable-technology .path7:before {
  content: "\f127";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path8:before {
  content: "\f128";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wearable-technology .path9:before {
  content: "\f129";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wearable-technology .path10:before {
  content: "\f12a";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path11:before {
  content: "\f12b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path12:before {
  content: "\f12c";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path13:before {
  content: "\f12d";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-wearable-technology .path14:before {
  content: "\f12e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path15:before {
  content: "\f12f";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path16:before {
  content: "\f130";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path17:before {
  content: "\f131";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path18:before {
  content: "\f132";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path19:before {
  content: "\f133";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wearable-technology .path20:before {
  content: "\f134";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wearable-technology .path21:before {
  content: "\f135";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wearable-technology .path22:before {
  content: "\f136";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-wearable-technology .path23:before {
  content: "\f137";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wearable-technology .path24:before {
  content: "\f138";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wearable-technology .path25:before {
  content: "\f139";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wearable-technology .path26:before {
  content: "\f13a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wearable-technology .path27:before {
  content: "\f13b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wearable-technology .path28:before {
  content: "\f13c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path1:before {
  content: "\f13d";
  color: rgb(230, 231, 232);
}
.icon-weather-forecast .path2:before {
  content: "\f13e";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-weather-forecast .path3:before {
  content: "\f13f";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-weather-forecast .path4:before {
  content: "\f140";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-weather-forecast .path5:before {
  content: "\f141";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-weather-forecast .path6:before {
  content: "\f142";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path7:before {
  content: "\f143";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path8:before {
  content: "\f144";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path9:before {
  content: "\f145";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path10:before {
  content: "\f146";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path11:before {
  content: "\f147";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path12:before {
  content: "\f148";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path13:before {
  content: "\f149";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path14:before {
  content: "\f14a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path15:before {
  content: "\f14b";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path16:before {
  content: "\f14c";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path17:before {
  content: "\f14d";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path18:before {
  content: "\f14e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path19:before {
  content: "\f14f";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-weather-forecast .path20:before {
  content: "\f150";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-weather-forecast .path21:before {
  content: "\f151";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-weather-forecast .path22:before {
  content: "\f152";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path23:before {
  content: "\f153";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path24:before {
  content: "\f154";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path25:before {
  content: "\f155";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path26:before {
  content: "\f156";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path27:before {
  content: "\f157";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path28:before {
  content: "\f158";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path29:before {
  content: "\f159";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path30:before {
  content: "\f15a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path31:before {
  content: "\f15b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path32:before {
  content: "\f15c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-weather-forecast .path33:before {
  content: "\f15d";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wifi .path1:before {
  content: "\f15e";
  color: rgb(230, 231, 232);
}
.icon-wifi .path2:before {
  content: "\f15f";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wifi .path3:before {
  content: "\f160";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wifi .path4:before {
  content: "\f161";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wifi .path5:before {
  content: "\f162";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wifi .path6:before {
  content: "\f163";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wifi .path7:before {
  content: "\f164";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wifi .path8:before {
  content: "\f165";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wifi .path9:before {
  content: "\f166";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wifi .path10:before {
  content: "\f167";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wifi .path11:before {
  content: "\f168";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wifi .path12:before {
  content: "\f169";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wifi .path13:before {
  content: "\f16a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path1:before {
  content: "\f16b";
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path2:before {
  content: "\f16c";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path3:before {
  content: "\f16d";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path4:before {
  content: "\f16e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path5:before {
  content: "\f16f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path6:before {
  content: "\f170";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path7:before {
  content: "\f171";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path8:before {
  content: "\f172";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path9:before {
  content: "\f173";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wind-energy .path10:before {
  content: "\f174";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wind-energy .path11:before {
  content: "\f175";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wind-energy .path12:before {
  content: "\f176";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wind-energy .path13:before {
  content: "\f177";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wind-energy .path14:before {
  content: "\f178";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wind-energy .path15:before {
  content: "\f179";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wind-energy .path16:before {
  content: "\f17a";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wind-energy .path17:before {
  content: "\f17b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path18:before {
  content: "\f17c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path19:before {
  content: "\f17d";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path20:before {
  content: "\f17e";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path21:before {
  content: "\f17f";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path22:before {
  content: "\f180";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path23:before {
  content: "\f181";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path24:before {
  content: "\f182";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path25:before {
  content: "\f183";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wind-energy .path26:before {
  content: "\f184";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path1:before {
  content: "\f185";
  color: rgb(230, 231, 232);
}
.icon-wireless-headset .path2:before {
  content: "\f186";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-headset .path3:before {
  content: "\f187";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-headset .path4:before {
  content: "\f188";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-headset .path5:before {
  content: "\f189";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-headset .path6:before {
  content: "\f18a";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path7:before {
  content: "\f18b";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wireless-headset .path8:before {
  content: "\f18c";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wireless-headset .path9:before {
  content: "\f18d";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path10:before {
  content: "\f18e";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-headset .path11:before {
  content: "\f18f";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wireless-headset .path12:before {
  content: "\f190";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path13:before {
  content: "\f191";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-headset .path14:before {
  content: "\f192";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wireless-headset .path15:before {
  content: "\f193";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path16:before {
  content: "\f194";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path17:before {
  content: "\f195";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-headset .path18:before {
  content: "\f196";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-headset .path19:before {
  content: "\f197";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path20:before {
  content: "\f198";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path21:before {
  content: "\f199";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path22:before {
  content: "\f19a";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path23:before {
  content: "\f19b";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-headset .path24:before {
  content: "\f19c";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path1:before {
  content: "\f19d";
  color: rgb(230, 231, 232);
}
.icon-wireless-mouse .path2:before {
  content: "\f19e";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-mouse .path3:before {
  content: "\f19f";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-mouse .path4:before {
  content: "\f1a0";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-mouse .path5:before {
  content: "\f1a1";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-mouse .path6:before {
  content: "\f1a2";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-mouse .path7:before {
  content: "\f1a3";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-mouse .path8:before {
  content: "\f1a4";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wireless-mouse .path9:before {
  content: "\f1a5";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wireless-mouse .path10:before {
  content: "\f1a6";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-mouse .path11:before {
  content: "\f1a7";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-mouse .path12:before {
  content: "\f1a8";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-mouse .path13:before {
  content: "\f1a9";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-wireless-mouse .path14:before {
  content: "\f1aa";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-mouse .path15:before {
  content: "\f1ab";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path16:before {
  content: "\f1ac";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path17:before {
  content: "\f1ad";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path18:before {
  content: "\f1ae";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path19:before {
  content: "\f1af";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path20:before {
  content: "\f1b0";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path21:before {
  content: "\f1b1";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-mouse .path22:before {
  content: "\f1b2";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path1:before {
  content: "\f1b3";
  color: rgb(230, 231, 232);
}
.icon-wireless-printer .path2:before {
  content: "\f1b4";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-printer .path3:before {
  content: "\f1b5";
  margin-left: -1em;
  color: rgb(230, 231, 232);
}
.icon-wireless-printer .path4:before {
  content: "\f1b6";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-wireless-printer .path5:before {
  content: "\f1b7";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wireless-printer .path6:before {
  content: "\f1b8";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wireless-printer .path7:before {
  content: "\f1b9";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wireless-printer .path8:before {
  content: "\f1ba";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-wireless-printer .path9:before {
  content: "\f1bb";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-printer .path10:before {
  content: "\f1bc";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-printer .path11:before {
  content: "\f1bd";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wireless-printer .path12:before {
  content: "\f1be";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-wireless-printer .path13:before {
  content: "\f1bf";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-printer .path14:before {
  content: "\f1c0";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-printer .path15:before {
  content: "\f1c1";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-wireless-printer .path16:before {
  content: "\f1c2";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-wireless-printer .path17:before {
  content: "\f1c3";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path18:before {
  content: "\f1c4";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path19:before {
  content: "\f1c5";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path20:before {
  content: "\f1c6";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path21:before {
  content: "\f1c7";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path22:before {
  content: "\f1c8";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path23:before {
  content: "\f1c9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-wireless-printer .path24:before {
  content: "\f1ca";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path1:before {
  content: "\f1cb";
  color: rgb(230, 231, 232);
}
.icon-zero-emission .path2:before {
  content: "\f1cc";
  margin-left: -1em;
  color: rgb(212, 251, 255);
}
.icon-zero-emission .path3:before {
  content: "\f1cd";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-zero-emission .path4:before {
  content: "\f1ce";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-zero-emission .path5:before {
  content: "\f1cf";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-zero-emission .path6:before {
  content: "\f1d0";
  margin-left: -1em;
  color: rgb(117, 168, 250);
}
.icon-zero-emission .path7:before {
  content: "\f1d1";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-zero-emission .path8:before {
  content: "\f1d2";
  margin-left: -1em;
  color: rgb(255, 236, 179);
}
.icon-zero-emission .path9:before {
  content: "\f1d3";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-zero-emission .path10:before {
  content: "\f1d4";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-zero-emission .path11:before {
  content: "\f1d5";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-zero-emission .path12:before {
  content: "\f1d6";
  margin-left: -1em;
  color: rgb(109, 201, 247);
}
.icon-zero-emission .path13:before {
  content: "\f1d7";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-zero-emission .path14:before {
  content: "\f1d8";
  margin-left: -1em;
  color: rgb(0, 137, 239);
}
.icon-zero-emission .path15:before {
  content: "\f1d9";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path16:before {
  content: "\f1da";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path17:before {
  content: "\f1db";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path18:before {
  content: "\f1dc";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path19:before {
  content: "\f1dd";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
.icon-zero-emission .path20:before {
  content: "\f1de";
  margin-left: -1em;
  color: rgb(58, 44, 96);
}
