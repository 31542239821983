































































.img-slider {
  &--bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &--mask {
    position: absolute;
    z-index: 2;
    width: 10%;
    height: 100%;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    top: 0;
    left: 0;
  }

  &--icon {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 7%;
      top: 63%;
      left: 45.2%;
    }
    &.img--2 {
      width: 7%;
      top: 63%;
      left: 55.1%;
    }
    &.img--3 {
      width: 7%;
      top: 62.4%;
      left: 64.8%;
    }
    &.img--4 {
      width: 5.5%;
      top: 64.4%;
      left: 75.5%;
    }
    &.img--5 {
      width: 6.5%;
      top: 63%;
      left: 84.5%;
    }
    &.img--6 {
      width: 6%;
      top: 63.8%;
      left: 94.5%;
    }
  }

  &--btn {
    position: absolute;
    z-index: 4;

    &.img--1 {
      width: 7%;
      top: 26.5%;
      left: 15.8%;
    }
    &.img--2 {
      width: 7%;
      top: 70.5%;
      left: 22.2%;
    }
    &.img--3 {
      width: 7%;
      top: 2.2%;
      left: 37.8%;
    }
    &.img--4 {
      width: 6.5%;
      top: 9.3%;
      left: 63.8%;
    }
    &.img--5 {
      width: 7%;
      top: 4.5%;
      left: 80.6%;
    }
    &.img--6 {
      width: 7%;
      top: 29%;
      left: 87%;
    }
  }
}

.gm-divider {
  position: absolute;
  background: #fff;
  z-index: 3;
  width: 55%;
  bottom: 14%;
  right: 0;
}

.icon-title {
  position: absolute;
  text-align: center;
  font-size: 0.8rem;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 6;
  width: 20%;
  top: 80%;

  &.icon--1 {
    left: 39%;
  }
  &.icon--2 {
    left: 48.5%;
  }
  &.icon--3 {
    left: 58.2%;
  }
  &.icon--4 {
    left: 68.3%;
  }
  &.icon--5 {
    left: 78%;
  }
  &.icon--6 {
    left: 86.5%;
  }
}

.text-slider {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  z-index: 7;

  &.gm-title {
    top: -7%;
    right: 1%;
    color: #212121;
  }
  &.text--1 {
    width: 50%;
    height: 15%;
    top: 83%;
    left: 6%;
  }
  &.text--2 {
    font-size: 0.8rem;
    width: 50%;
    height: 10%;
    top: 86%;
    left: 49%;

    strong {
      letter-spacing: 5px;
    }
  }
}
