





























































































$animation-timing: 20s;
$carousel-items: 5;
$animation-delay-fraction: $animation-timing / $carousel-items;
$animation-steps-fraction: 100 / $carousel-items;
$slide-change-timing: 3; // percentage of total animation cycle

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.carousel__item {
  display: flex;
  align-items: center;
  position: absolute;
  width: 100%;
  opacity: 0;
  // filter: drop-shadow(0 2px 2px #555);
  will-change: transform, opacity;
  animation: carousel-animate-vertical $animation-timing linear infinite;

  span {
    margin-left: auto;
  }
}

@for $i from 1 through ($carousel-items - 1) {
  .carousel__item:nth-child(#{$i}) {
    animation-delay: calc(#{$animation-delay-fraction} * #{$i - 2});
  }
}

.carousel__item:last-child {
  animation-delay: calc(-#{$animation-delay-fraction} * 2);
}

// example without negative delays
// @for $i from 1 through ($carousel-items) {
//   .carousel__item:nth-child(#{$i}) {
//     animation-delay: calc(#{$animation-delay-fraction} * #{$i});
//   }
// }

@keyframes carousel-animate-vertical {
  0% {
    transform: translateY(-100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
  #{$slide-change-timing}%,
  #{$animation-steps-fraction}% {
    transform: translateY(-100%) scale(0.7);
    opacity: 0.4;
    visibility: visible;
  }
  #{$animation-steps-fraction + $slide-change-timing}%,
  #{$animation-steps-fraction * 2}% {
    transform: translateY(0) scale(1);
    opacity: 1;
    visibility: visible;
  }
  #{($animation-steps-fraction * 2) + $slide-change-timing}%,
  #{$animation-steps-fraction * 3}% {
    transform: translateY(100%) scale(0.7);
    opacity: 0;
    visibility: visible;
  }
  #{($animation-steps-fraction * 3) + $slide-change-timing}% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: translateY(100%) scale(0.5);
    opacity: 0;
    visibility: hidden;
  }
}
