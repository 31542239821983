














.webpage {
  padding-right: 0px !important;
}

.img-slider {
  &--bg {
    position: absolute;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
