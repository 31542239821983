












































































.gm-image {
  transform: translatey(0px);

  &.img {
    &--1 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 45%;
      left: -40%;
      z-index: 4;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 17%;
      left: -22%;
      z-index: 1;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 46%;
      left: 14%;
      z-index: 3;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 100%;
      top: 34%;
      left: -2%;
      z-index: 2;
    }
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 24%;
  height: 24%;

  &.control {
    &--1 {
      top: 66%;
      left: 2%;
      z-index: 7;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 42%;
      left: 20%;
      z-index: 7;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      width: 11%;
      height: 34%;
      top: 45%;
      left: 44%;
      z-index: 7;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 45%;
      left: 55%;
      z-index: 7;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .btn-line--4 {
          opacity: 1;
        }
      }
    }
  }
}
