@import '~vuetify/dist/vuetify.min.css';
@import url('https://fonts.googleapis.com/css2?family=Cabin&display=swap');
@import './_breakpoints.scss';
@import url("./icomoon/style.css");
@import url('https://cdn.materialdesignicons.com/5.4.55/css/materialdesignicons.min.css');

i > span {
  font-family: 'icomoon' !important;
}

*, .icon-title {
  font-family: 'Cabin', sans-serif !important;
}

.webpage {
  max-width: 100% !important;
  // height: auto;

  &.main {
    overflow: hidden;
    height: 100%;
  }

  @include screen_md {
    height: 100vh;

    &.main {
      overflow: inherit;
    }
  }
}

.full-height {
  min-height: 100vh;

  &.section-1 {
    min-height: calc(100vh + 12px);
  }
}

.gm-headline {
  color: white !important;

  &-1 {
    color: white !important;
    font-size: 2.2rem !important;

    @include screen_md {
      font-size: 3.2rem !important;
    }
  }

  &-2 {
    color: white !important;
    font-size: 1.4rem !important;

    @include screen_md {
      font-size: 2.4rem !important;
    }
  }

  &-3 {
    color: white !important;
    font-size: 1.2rem !important;

    @include screen_md {
      font-size: 2rem !important;
    }
  }

  &-4 {
    font-size: 1rem !important;

    @include screen_md {
      font-size: 1.4rem !important;
    }
  }
}

.gm-image {
  position: absolute !important;

  &__container {
    position: relative !important;
    // background: indigo;
    width: 100%;
    // max-width: 50vw;

    @include screen_xs {
      max-width: 80vw;
    }
    @include screen_md {
      max-width: 44vw;
    }
    @include screen_lg {
      max-width: 40vw;
    }
    @include screen_xl {
      max-width: 48vw;
    }

    &:after {
      content: '';
      display: block;
      padding-bottom: 100%;
    }

    &.rectangle {
      width: 100% !important;

      @include screen_xs {
        max-width: 100vw;
      }
      @include screen_md {
        max-width: 75vw;
      }
      @include screen_lg {
        max-width: 75vw;
      }
      @include screen_xl {
        max-width: 75vw;
      }

      &:after {
        content: '';
        display: block;
        padding-bottom: 50%;
      }
    }
  }
}

.gm-floating-img {
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}

.gm-card__shadow {
  border: solid 1px #eaeaea !important;
  box-shadow: 0px 15px 30px 0px rgba(0, 0, 0, 0.1) !important;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}