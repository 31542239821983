








































































































.v-progress-linear {
  position: absolute;
  bottom: 50%;
  transform: rotate(90deg);
}

.btn--brightness {
  -webkit-box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 35px 0px rgba(255, 255, 255, 0.3);
}
