

















































































































span {
  font-size: 1.15rem;
}

.gm-image {
  transform: translatey(0px);

  &.img {
    &--main {
      transition: all 0.25s ease !important;
      width: 75%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
    }
    &--1 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 16%;
      left: 4%;
      z-index: 2;
    }
    &--2 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 7%;
      left: 25%;
      z-index: 3;
    }
    &--3 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 9%;
      left: 52%;
      z-index: 4;
    }
    &--4 {
      transition: all 0.25s ease !important;
      width: 24%;
      top: 19%;
      left: 77%;
      z-index: 5;
    }
    &--5 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 51%;
      left: 76%;
      z-index: 6;
    }
    &--6 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 57%;
      left: 52%;
      z-index: 7;
    }
    &--7 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 65%;
      left: 22%;
      z-index: 8;
    }
    &--8 {
      transition: all 0.25s ease !important;
      width: 26%;
      top: 49%;
      left: -5%;
      z-index: 9;
    }
  }
}

.gm-title {
  transition: all 0.25s ease !important;
  position: absolute;
  opacity: 0;
  // background: rgba(0, 0, 0, 0.5);

  &.title {
    &--1 {
      width: 30%;
      z-index: 10;
      top: 26%;
      left: -22%;
    }
    &--2 {
      width: 35%;
      z-index: 10;
      top: -2%;
      left: 19%;
    }
    &--3 {
      width: 35%;
      z-index: 10;
      top: 0%;
      left: 47%;
    }
    &--4 {
      width: 27%;
      z-index: 10;
      top: 2%;
      left: 75%;
    }
    &--5 {
      width: 27%;
      z-index: 10;
      top: 73%;
      left: 76%;
    }
    &--6 {
      width: 35%;
      z-index: 10;
      top: 82%;
      left: 47%;
    }
    &--7 {
      width: 35%;
      z-index: 10;
      top: 86%;
      left: 17%;
    }
    &--8 {
      width: 35%;
      z-index: 10;
      top: 73%;
      left: -9%;
    }
  }
}

.hover-control {
  position: absolute;
  // background: rgba(0, 0, 0, 0.5);
  width: 21%;
  height: 21%;

  &.control {
    &--1 {
      top: 24%;
      left: 5%;
      z-index: 11;

      &:hover {
        & ~ .img--1 {
          transform: translatey(-5px);
        }

        & ~ .title--1 {
          opacity: 1;
        }
      }
    }
    &--2 {
      top: 10%;
      left: 26%;
      z-index: 11;

      &:hover {
        & ~ .img--2 {
          transform: translatey(-5px);
        }

        & ~ .title--2 {
          opacity: 1;
        }
      }
    }
    &--3 {
      top: 10%;
      left: 54%;
      z-index: 11;

      &:hover {
        & ~ .img--3 {
          transform: translatey(-5px);
        }

        & ~ .title--3 {
          opacity: 1;
        }
      }
    }
    &--4 {
      top: 24%;
      left: 78%;
      z-index: 11;

      &:hover {
        & ~ .img--4 {
          transform: translatey(-5px);
        }

        & ~ .title--4 {
          opacity: 1;
        }
      }
    }
    &--5 {
      top: 52%;
      left: 79%;
      z-index: 11;

      &:hover {
        & ~ .img--5 {
          transform: translatey(-5px);
        }

        & ~ .title--5 {
          opacity: 1;
        }
      }
    }
    &--6 {
      top: 61%;
      left: 54%;
      z-index: 11;

      &:hover {
        & ~ .img--6 {
          transform: translatey(-5px);
        }

        & ~ .title--6 {
          opacity: 1;
        }
      }
    }
    &--7 {
      top: 65%;
      left: 24%;
      z-index: 11;

      &:hover {
        & ~ .img--7 {
          transform: translatey(-5px);
        }

        & ~ .title--7 {
          opacity: 1;
        }
      }
    }
    &--8 {
      top: 52%;
      left: -2%;
      z-index: 11;

      &:hover {
        & ~ .img--8 {
          transform: translatey(-5px);
        }

        & ~ .title--8 {
          opacity: 1;
        }
      }
    }
  }
}
